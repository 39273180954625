import "./loginStyles.css";
import { useAuth0 } from "@auth0/auth0-react";
import LVAdminLogo from "./lv-admin-logo.png";
import { useEffect, useState } from "react";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  return (
    <div className="app" style={{ background: "#EFF3FB" }}>
      <div className="login">
        <div className="formElement">
          <span className="heading">
            <img alt="lv_logo" width={200} src={LVAdminLogo} />
          </span>
        </div>

        <ActionButton
          customStyles={{
            width: "100%",
            backgroundColor: "#0F4593",
            textTransform: "none",
          }}
          onClick={() => {
            setIsLoading(true);
            loginWithRedirect();
          }}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Sign In
        </ActionButton>
        {/* </Box> */}
      </div>
    </div>
  );
}
