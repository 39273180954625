import { Box, Grid } from "@mui/material";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { useContext } from "react";
import { useState } from "react";
import { FC } from "react";
import { AuthContext } from "../../../../../contexts/authContext";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
};

const AddUserModal: FC<NoteModalType> = (props) => {
  const authContext = useContext(AuthContext);
  const { isOpen, handleClose } = props;

  const [selectedUser, setSelectedUser] = useState<any>({});
  const [userOption, setUserOption] = useState<any>([]);

  const handleSubmit = () => {
    handleClose();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="Add Platform User"
      submitText="&nbsp;&nbsp;Add&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Cancel&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      <Box width="30vw">
        <Grid container gap={2}>
          <Box width="100%">
            <Grid item>
              <LvAutoComplete
                label="Select Users"
                options={userOption}
                setOptions={setUserOption}
                selectedObject={selectedUser}
                setSelectedObject={setSelectedUser}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default AddUserModal;
