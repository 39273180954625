import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { isEmpty } from "../../../../utils/commonUtils";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { toast } from "react-toastify";
import LvConfigManager from "../../managers/lvConfigManagers";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Technical Configurations",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Lv Configs",
    navigationButton: false,
  },
};

const useStyles = makeStyles({
  analyticsContainer: {
    backgroundColor: "rgb(230 230 230)",
    height: "8rem",
    border: "1px solid #dedede",
    width: "100%",
    marginLeft: "1.5rem",
    marginTop: "1.5rem",
    borderRadius: "6px",
  },
  analyticsInnerContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    "& .MuiTypography-root": {
      fontWeight: 600,
    },
  },
  customModalWidth: {
    width: "40rem",
  },
});

const LvConfig = () => {
  const classes: any = useStyles();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const lvConfigManager = new LvConfigManager();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [value, setValue] = useState<any>({
    name: null,
    value: null,
    dessciption: null,
  });

  let columns: any = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
    },
    {
      field: "value",
      headerName: "Value",
      minWidth: 500,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 400,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 120,
      renderCell: (params: any) => {
        return (
          <ActionButton
            customStyles={{
              backgroundColor: getColor("action_button", 200),
              color: getColor("shades", 0),
              opacity: 0.9,
              "&:hover": {
                opacity: 1,
                backgroundColor: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),

                boxShadow: "none",
              },
              borderColor: getColor("action_button", 200),
              minWidth: "auto",
              paddingX: "10px",
              paddingY: "5px",
            }}
            onClick={() => {
              setIsOpen(true);
              setValue(getModalData(params.row));
            }}
          >
            Edit
          </ActionButton>
        );
      },
    },
  ];

  const fetchLvConfigData = async () => {
    setLoading(true);
    try {
      let result = await lvConfigManager.getLvConfigs(page);
      setFormattedData(result?.formattedData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getModalData = (row: any) => {
    return {
      name: row.name,
      value: row.value,
      description: row.description,
    };
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const handleModalClose = () => {
    setIsSubmitLoading(false);
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    const result = await lvConfigManager.updateLvConfigs({
      name: value.name,
      value: value.value,
      description: value.description,
    });
    if (result.success) {
      toast.success(result?.message);
      fetchLvConfigData();
      setIsOpen(false);
    } else {
      toast.error("Something went wrong");
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    fetchLvConfigData();
  }, []);

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <LvComplexTable
          rows={formattedData}
          columns={columns}
          rowHeight={40}
          headerHeight={60}
          minWidth={1000}
          filterMode="server"
          enableAdvancedFiltering
          isUnFilteredDataPresent={!isEmpty(formattedData)}
          enableAutoHeight={true}
          pageSize={30}
          page={page}
          enablePagination
          totalRowCount={count}
          handlePageChange={handlePageChange}
          paginationMode="server"
          rightPinnedColumns={["action"]}
        />
        <CommonModal
          isOpen={isOpen}
          handleClose={handleModalClose}
          title="Edit Lv Configs"
          isLoading={isSubmitLoading}
          cancelText="Cancel"
          submitText="Submit"
          handleSubmit={handleSubmit}
          muiClasses={{
            paper: classes.customModalWidth,
          }}
        >
          <Grid container>
            <Grid item container direction="column" spacing={3}>
              <Grid item>{value?.name}</Grid>
              <Grid item>
                <CustomTextField
                  label="Value"
                  onChange={(val: string) => setValue({ ...value, value: val })}
                  value={value?.value}
                  multiline
                />
              </Grid>
              <Grid item>
                <CustomTextField
                  label="Description"
                  onChange={(val: string) =>
                    setValue({ ...value, description: val })
                  }
                  value={value?.description}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        </CommonModal>
      </Grid>
    </Box>
  );
};

export default LvConfig;
