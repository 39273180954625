import React, { FC, ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LVThemeProvider } from "../Styles/Themes";

type Props = {
  children: ReactNode;
};
const ThemeContext: FC<Props> = (props) => {
  return (
    <LVThemeProvider>
      <ToastContainer />
      {props?.children}
    </LVThemeProvider>
  );
};

export default ThemeContext;
