import React, { ReactNode } from "react";
import { Button } from "@mui/material";
import { getColor } from "../../Styles/Colors";

// Define the props type
interface TextButtonProps {
  onClick: () => void;
  children: ReactNode;
}

const TextButton: React.FC<TextButtonProps> = (props) => {
  const { onClick, children } = props;
  return (
    <Button
      disableRipple
      onClick={onClick}
      style={{
        color: getColor("action_button", 300),
        textTransform: "none",
      }}
    >
      {children}
    </Button>
  );
};

export default TextButton;
