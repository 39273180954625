export const INVESTMENT_TYPE = {
  AIF: {
    label: "AIF",
    value: "aif",
  },
  DIRECT: {
    label: "Direct",
    value: "direct",
  },
};

export const INVESTOR_RANKING = {
  NA: {
    label: "N/A",
    value: null,
  },
  P0: {
    label: "P0",
    value: 0,
  },
  P1: {
    label: "P1",
    value: 1,
  },
};

export const SCHEME_DOC_STATUS = {
  NA: {
    label: "N/A",
    value: null,
  },
  SENT: {
    label: "Sent",
    value: "Sent",
  },
  VIEWED: {
    label: "Viewed",
    value: "Viewed",
  },
  TO_BE_SENT: {
    label: "To be sent",
    value: "To be sent",
  },
};

export const DealConsentOptions = [
  {
    label: "N/A",
    value: null,
  },
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

export const TransferTypeOptions = [
  {
    label: "N/A",
    value: null,
  },
  {
    label: "Foreign",
    value: "foreign",
  },
  {
    label: "Indian",
    value: "indian",
  },
  {
    label: "NRO",
    value: "nro",
  },
  {
    label: "NRE",
    value: "nre",
  },
];
