import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = (props: any) => {
  const { children } = props;
  const isAuthenticated = localStorage.getItem("token");
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="login" replace />;
  }
  if (isAuthenticated && location.pathname === "/")
    return <Navigate to="/dashboard" replace />;

  return children;
};

export default PrivateRoute;
