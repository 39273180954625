import { Box, Grid, Stack } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import React, { useContext, useState, useEffect } from "react";
import {
  BreadCrumbDataInvestorDocument,
  DocumentCategoryOptions,
  DocumentTypeNames,
} from "../../constants/documentConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { API_STATUS } from "../../../../constants/commonConstants";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { isEmpty } from "lodash";
import DocumentManagement from "../../managers/documentManager";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { toast } from "react-toastify";
import UploadInvestorDocumentModal from "./modal/uploadInvestorDocumentModal";
import BulkUploadModal from "./modal/bulkUploadModal";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

const InvestorDocuments = () => {
  const DocumentCategoryOptionsExtended = [
    { label: "All", value: "all" },
    ...DocumentTypeNames,
  ];

  const [selectedUser, setSelectedUser] = useState<any>({});
  const [userOptions, setUserOptions] = useState<any>([]);

  const [documentCategory, setDocumentCategory] = useState<any>({});

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);
  const userManagementManager = new UserManagementManager(userData);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [
    isUploadInvestorDocumentModalOpen,
    setIsUploadInvestorDocumentModalOpen,
  ] = useState(false);
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);

  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    fetchData({ page: page + 1 });
  };

  const handleSearchClick = () => {
    console.log("selectedUser", selectedUser);
    fetchData({ user_id: selectedUser.value });
  };

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setUserOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  type GetDataParams = {
    page?: number;
    user_id?: any;
    investor_information_id?: any;
    document_title?: string;
  };

  const fetchData = async ({
    page = 1,
    user_id = null,
    investor_information_id = null,
    document_title = "",
  }: GetDataParams = {}) => {
    setApiStatus(API_STATUS.LOADING);
    try {
      const { formattedData, count } =
        await documentManagementManager.getInvestorDocuments(
          page,
          user_id,
          investor_information_id,
          document_title
        );
      setFormattedData(formattedData);
      !!count && setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error) {
      setApiStatus(API_STATUS.ERROR);
      console.error("Error fetching documents:", error);
    }
  };

  const getAction = (data: any) => {
    if (data?.document_title == "Onboarding") {
      // console.log("");
      if (data?.status == 0) {
        // data={'type': data?.document_type}
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("success", 500),
            }}
            onClick={async () => {
              const res = await documentManagementManager.approveOnboardingDoc(
                data?.id
              );
              fetchData();
            }}
            tableAction
          >
            Approve
          </OutlinedButton>
        );
      } else if (data?.status === 1) {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("error", 500),
            }}
            onClick={async () => {
              const res = await documentManagementManager.rejectOnboardingDoc(
                data?.id,
                "rejected in testing"
              );
              fetchData();
            }}
            tableAction
          >
            Reject
          </OutlinedButton>
        );
      } else if (data?.status === 2) {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("success", 500),
            }}
            onClick={async () => {
              const res = await documentManagementManager.approveOnboardingDoc(
                data?.id
              );
              fetchData();
            }}
            tableAction
          >
            Approve
          </OutlinedButton>
        );
      } else if (!data?.publish) {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("success", 500),
            }}
            onClick={async () => {
              const res = await documentManagementManager.approveOnboardingDoc(
                data?.id
              );
              fetchData();
            }}
            tableAction
          >
            Approve
          </OutlinedButton>
        );
      }
    } else {
      if (data?.publish) {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("error", 500),
            }}
            onClick={async () => {
              const res = await documentManagementManager.unPublishInvestorDoc(
                data?.id
              );
              fetchData();
            }}
            tableAction
          >
            Unpublish
          </OutlinedButton>
        );
      } else {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("success", 500),
            }}
            onClick={async () => {
              const res = await documentManagementManager.publishInvestorDoc(
                data?.id
              );
              fetchData();
            }}
            tableAction
          >
            Publish
          </OutlinedButton>
        );
      }
    }
  };

  const handleCloseUploadInvestorDocumentModal = () => {
    setIsUploadInvestorDocumentModalOpen(false);
  };
  const handleOpenUploadInvestorDocumentModal = () => {
    setIsUploadInvestorDocumentModalOpen(true);
  };

  const handleCloseBulkUploadModal = () => {
    setIsBulkUploadModalOpen(false);
  };
  const handleOpenBulkUploadModal = () => {
    setIsBulkUploadModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 100,
    },
    {
      field: "investor_name",
      headerName: "Investor Name",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "formated_document_name",
      headerName: "Document Category",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "formated_document_category",
      headerName: "Document Name",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "last_updated_date",
      headerName: "Uploaded Date",
      sortable: false,
      flex: 2,
      minWidth: 180,
    },
    {
      field: "last_updated_by",
      headerName: "Last Uploaded By",
      sortable: false,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 2,
      minWidth: 300,
      align: "center",
      renderCell: (params: any) => {
        return (
          <Stack direction="row" gap={3}>
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 200),
                borderColor: getColor("action_button", 200),
              }}
              onClick={() => {
                viewFile(params.row?.document_url);
              }}
              tableAction
            >
              View
            </OutlinedButton>

            <>{getAction(params.row)}</>
            {/* <OutlinedButton
              customStyles={{
                color: getColor("error", 500),
              }}
              onClick={() => {
                getAction(params.row)
                
              }}
            >
              Reject
            </OutlinedButton> */}
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataInvestorDocument?.steps}
            pageHeader={BreadCrumbDataInvestorDocument.pageHeader}
            stepKey={BreadCrumbDataInvestorDocument.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <ActionButton
            onClick={() => {
              handleOpenBulkUploadModal();
            }}
          >
            Bulk Upload Document
          </ActionButton>
          <ActionButton
            onClick={() => {
              handleOpenUploadInvestorDocumentModal();
            }}
          >
            Upload Document
          </ActionButton>
          <Box>
            <LvAutoComplete
              label="Enter user name or email"
              options={userOptions}
              setOptions={setUserOptions}
              selectedObject={selectedUser}
              setSelectedObject={setSelectedUser}
              onSelect={() => setUserOptions([])}
              onChange={handleTextChange}
            />
          </Box>
          <Box width={"190px"}>
            <CoreDropdown
              label="Document Category"
              options={DocumentCategoryOptionsExtended}
              value={documentCategory}
              onClickMenuItem={async (value: any) => {
                await fetchData({ document_title: value.value });
                setDocumentCategory(value);
              }}
            />
          </Box>
          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>

      {apiStatus === API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={20}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <LvComplexTable
          rows={formattedData}
          columns={columns}
          rowHeight={40}
          headerHeight={50}
          minWidth={750}
          filterMode="server"
          leftPinnedColumns={["name"]}
          enableAdvancedFiltering
          isUnFilteredDataPresent={!isEmpty(formattedData)}
          enableAutoHeight={true}
          pageSize={20}
          page={page}
          enablePagination
          totalRowCount={count}
          handlePageChange={handlePageChange}
          paginationMode="server"
        />
      )}

      <UploadInvestorDocumentModal
        isOpen={isUploadInvestorDocumentModalOpen}
        handleClose={handleCloseUploadInvestorDocumentModal}
        selected={null}
      />

      <BulkUploadModal
        isOpen={isBulkUploadModalOpen}
        handleClose={handleCloseBulkUploadModal}
        selected={null}
      />
    </Box>
  );
};

export default InvestorDocuments;
