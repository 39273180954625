import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { FC } from "react";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
};

const RejectWithoutMail: FC<NoteModalType> = (props) => {
  const { isOpen, handleClose, selected } = props;
  const handleReject = () => {
    console.log("rejected");
    handleClose();
  };
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleReject}
      title="Reject without Message"
      submitText="&nbsp;&nbsp;Reject&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Cancel&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      You are rejecting this Investor without Email notification
    </CommonModal>
  );
};

export default RejectWithoutMail;
