import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/v1/admin";
const API_BASE_URL2 = "https://api.staging.letsventure.com/v1";

export const getInitialScreeningDocuments = (
  status: string,
  page: number,
  name: string,
  order: string,
  sector_id: string
) => {
  const finalURL =
    API_BASE_URL +
    `/startup_management/initial_screening/?tab=${status}&page=${page}&name=${name}&order=${order}&sector_id=${sector_id}`;
  return Axios.get(finalURL);
};

export const getFinalScreeningDocuments = (
  status: string,
  page: number,
  sortBy: string,
  sortByDoc: string,
  dealType: string,
  sectorId: string,
  sectorPoc: string,
  name: string
) => {
  const finalURL =
    API_BASE_URL +
    `/startup_management/final_screening/?tab=${status}&page=${page}&name=${name}&sector_id=${sectorId}&order=${sortBy}`;
  return Axios.get(finalURL);
};

export const getStartupDetail = (startup_id: string) => {
  const finalURL =
    API_BASE_URL +
    `/startup_detail/get_startup_details/?startup_id=${startup_id}`;
  return Axios.get(finalURL);
};

export const startupActions = (
  startup_id: string,
  action: string,
  data: any
) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const finalURL =
    API_BASE_URL +
    `/startup_detail/admin_action/?startup_id=${startup_id}&todo=${action}`;
  return Axios.put(finalURL, data, config);
};

export const initialScreeningMarkAsGood = (data: any) => {
  const finalURL =
    API_BASE_URL + `/startup_management/initial_screening/mark_as_good/`;
  return Axios.put(finalURL, data);
};

export const initialScreeningAction = (data: any) => {
  const finalURL =
    API_BASE_URL +
    `/startup_management/initial_screening/initial_screening_action/`;
  return Axios.put(finalURL, data);
};

export const finalScreeningAction = (data: any) => {
  const finalURL =
    API_BASE_URL +
    `/startup_management/final_screening/final_screening_action/`;
  return Axios.put(finalURL, data);
};

export const getStartupReports = (startupId: string, page: number) => {
  const finalURL =
    API_BASE_URL2 +
    `/startup_reports/?startup_id=${startupId}&admin_view=true&page=${page}`;
  return Axios.get(finalURL);
};

export const parseReport = () => {
  const finalURL = API_BASE_URL2 + `/startup_reports/parse_report/`;
  return Axios.get(finalURL);
};

export const reportAction = (startupId: string, data: any) => {
  const finalURL = API_BASE_URL2 + `/startup_reports/${startupId}/`;
  return Axios.patch(finalURL, data);
};

export const startupReportAction = (reportId: string, status: string) => {
  const finalURL = API_BASE_URL2 + `/startup_reports/publish_portfolio_update/?id=${reportId}&status=${status}`
  return Axios.get(finalURL);
};

export const SendInvestorCommunication = (data: any) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const finalURL = API_BASE_URL2 + `/startup_reports/send_email_to_startup_committed_investors/`;
  return Axios.post(finalURL, data, config)
};

export const getCommitmentInvestorCommunication = (
  page: number,
 ) => {
  const finalURL = API_BASE_URL2 + `/startup_reports/get_portfolio_update_list/`;
  return Axios.get(finalURL);
};

export const searchDealName = (search: string) => {
  const finalURL =
    API_BASE_URL +
    `/lookups/get_lookup_data/?type=admin_search_startups&q=${search}`;
  return Axios.get(finalURL);
};