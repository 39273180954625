import { Typography, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Topbar } from "../../ui-library/navs/topbar";

import { ActionButton } from "../../ui-library/buttons/ActionButton";
import LVAdminLogo from "../auth/components/login/lv-admin-logo.png";

const PageNotFound = () => {
  const navigate = useNavigate();
  const PAGE_NOT_FOUND =
    "https://dppsg1pijs5gq.cloudfront.net/diy/images/page-not-found.svg";

  return (
    <div>
      <Topbar position="relative">
        <Link to="/">
          <img
            src={LVAdminLogo}
            alt="letsventure_logo"
            style={{ width: "200px", height: "auto" }}
          />
        </Link>
      </Topbar>

      <Grid container textAlign="center">
        <Grid
          xs={12}
          ml="-3%"
          sx={{
            width: { xs: "200px", lg: "auto" },
            ".pgn": { width: { xs: "100%", lg: "auto" } },
          }}
        >
          <img src={PAGE_NOT_FOUND} alt="page not found" className="pgn" />
        </Grid>
        <Grid xs={12} alignItems="center" mt={3} gap={{ xs: 0, lg: 2 }}>
          <Typography variant="heading_04_medium" textTransform="uppercase">
            Page not found
          </Typography>
          <Typography variant="subtitle2" mb={1}>
            Sorry, the page you are looking for doesn&apos;t exist
          </Typography>
          <ActionButton
            onClick={() => {
              navigate("/");
            }}
          >
            BACK TO DASHBOARD
          </ActionButton>
        </Grid>
      </Grid>
    </div>
  );
};
export default PageNotFound;
