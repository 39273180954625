import React, { useEffect, useState } from "react";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { Box, Grid } from "@mui/material";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Post Commit Status Tracker",
    navigationButton: false,
  },
};

export default function PostCommitStatusTracker() {
  // const userManagementManager = new UserManagementManager();

  const columns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "start_date",
      headerName: "PostCommitmentProcess Started",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "type",
      headerName: "Type of Round",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "close_date",
      headerName: "Syndicate Closed Date",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "update_date",
      headerName: "Status Last Updated Date",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "fundraise_amount",
      headerName: "Fundraise Amount",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "invoice_amount",
      headerName: "Invoice Amount	",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "details",
      headerName: "",
      flex: 6,
      minWidth: 340,
      align: "center",
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 200),
              borderColor: getColor("action_button", 200),
            }}
            tableAction
            // onClick={() => handleViewClick()}
          >
            Details
          </OutlinedButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      flex: 3,
      minWidth: 150,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 200),
              borderColor: getColor("action_button", 200),
            }}
            tableAction
          >
            Delete
          </OutlinedButton>
        );
      },
    },
  ];

  const tabs: any = [
    {
      name: "Live Deals",
      value: "live_deals",
    },
    {
      name: "Closed Deals",
      value: "closed_deals",
    },
  ];

  const [value, setValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data: any;
      switch (selectedTab) {
        case "awaiting":
          // data = await userManagementManager.getVerifyingInvestorAwaiting(
          //   value,
          //   page
          // );
          break;
        case "verified":
          // data = await userManagementManager.getVerifyingInvestorVerified(
          //   value,
          //   page
          // );
          break;

        default:
          break;
      }
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  useEffect(() => {
    getData();
  }, [page, selectedTab]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      <Box>
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={(tab: any) => {
            setValue("");
            setSelectedTab(tab);
          }}
          scrollable={false}
        />
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={60}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </Box>
    </Box>
  );
}
