import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/";

export const getVerifyingInvestor = (
  mode: string,
  search: string,
  page: number
) => {
  const finalURL =
    API_BASE_URL +
    `v1/admin/user_management/verify_investor/?mode=${mode}&search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};

export const getNewVerifyingInvestor = (
  mode: string,
  search: string,
  page: number
) => {
  const finalURL =
    API_BASE_URL +
    `v1/admin/user_management/new_verify_investor/?mode=${mode}&search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};

export const getNonSebiInvestors = (search: string, page: number) => {
  const finalURL =
    API_BASE_URL +
    `v1/admin/user_management/non_sebi_investors/?search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};

export const getLookupData = (
  search: string,
  type: string,
  user_search_private_hashtag?: string
) => {
  const params: { [key: string]: string | number | boolean | undefined } = {
    search: search,
    type: type,
    user_search_private_hashtag: user_search_private_hashtag,
  };

  const queryString = Object.entries(params)
    .reduce<string[]>((acc, [key, value]) => {
      if (value != null) {
        acc.push(`${key}=${encodeURIComponent(String(value))}`);
      }
      return acc;
    }, [])
    .join('&');

  const finalURL = `${API_BASE_URL}v1/admin/lookups/get_lookup_data/?${queryString}`;  // const finalURL =
  //   API_BASE_URL +
  //   `v1/admin/lookups/get_lookup_data/?type=${type}&q=${search}&user_search_private_hashtag=${user_search_private_hashtag}`;
  return Axios.get(finalURL);
};

export const getNoteForUser = (id: string, type: string) => {
  const finalURL =
    API_BASE_URL + `v1/admin/lv_notes/?resource_id=${id}&resource_type=${type}`;
  return Axios.get(finalURL);
};

export const addNoteForUser = (id: string, type: string, note: string) => {
  const user: any = localStorage.getItem("user");
  const userId = JSON.parse(user)?.id;
  const finalURL = API_BASE_URL + `v1/admin/lv_notes/`;
  const data = {
    note,
    resource_type: type,
    resource_id: id,
    user_id: userId,
  };
  return Axios.post(finalURL, data);
};

export const getUserDetail = (userId: string) => {
  const finalURL =
    API_BASE_URL + `v1/admin/user_detail/get_user_details/?user_id=${userId}`;
  return Axios.get(finalURL);
};

export const adminAction = (userId: string, todo: string, body: any) => {
  const finalURL =
    API_BASE_URL +
    `v1/admin/user_detail/admin_action/?user_id=${userId}&todo=${todo}`;
  return Axios.put(finalURL, body);
};

export const addUser = (data: any) => {
  const finalURL =
    API_BASE_URL +
    `v1/syndicates/invite_member/?type_of_member=user&type_of_action=invite`;
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return Axios.post(finalURL, data, config);
};

export const getInvestmentProfile = (search: string, investor_id: string) => {
  const finalURL =
    API_BASE_URL +
    `v1/admin/lookups/get_lookup_data/?type=document_vault_investor_informations&q=${search}&user_id=${investor_id}`;
  return Axios.get(finalURL);
};

export const getKycStatusData = (
  page: number,
  investor_id: string,
  stage: string,
  onboarding_complete: boolean | string
) => {
  let finalURL =
    API_BASE_URL +
    `v1/kyc/?admin_view=true&page=${page}&lv_review_stage=${stage}`;
  if (investor_id) {
    finalURL += `&user_id=${investor_id}`;
  }
  if (onboarding_complete !== "not_provided") {
    finalURL += `&onboarding_complete=${onboarding_complete}`;
  }
  return Axios.get(finalURL);
};

export const updateReviewStatus = (recordId: string, body: any) => {
  const finalURL = API_BASE_URL + `v1/kyc/${recordId}/`;
  return Axios.put(finalURL, body);
};

/**Verify Phone Number */
export const getVerifyingPhoneNumber = (
  mode: string,
  search: string,
  page: number
) => {
  const finalURL =
    API_BASE_URL +
    `v1/admin/user_management/onboard_phone_no_user_list/?mode=${mode}&search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};

export const getNewVerifyingPhoneNumber = (
  mode: string,
  search: string,
  page: number
) => {
  const finalURL =
    API_BASE_URL +
    `v1/admin/user_management/onboard_phone_no_user_list/?mode=${mode}&search_text=${search}&page=${page}`;
  return Axios.get(finalURL);
};
