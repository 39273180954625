import React, { FC, useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { AwaitingPhoneNumberType } from "./AwaitingPhoneNumber";

import NoteModal from "../modals/NoteModal";
import { Link } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";

const VerifiedPhoneNumber: FC<AwaitingPhoneNumberType> = (
  props: AwaitingPhoneNumberType
) => {
  const { setPage, formattedData, page, count } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [selected, setSelected] = useState<any>();
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/user/show-user/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "mobile",
      headerName: "Phone No.",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "startup",
      headerName: "Startup",
      flex: 1,
      minWidth: 150,
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={60}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={30}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
      <NoteModal
        isOpen={isOpen}
        handleClose={handleClose}
        selected={selected}
        type="User"
      />
    </div>
  );
};

export default VerifiedPhoneNumber;
