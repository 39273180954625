import { Suspense, useContext } from "react";
import { ApplicationLoader } from "../ui-library/loaders/application-loader";
import { Routes, Route, Outlet } from "react-router-dom";
import Home from "../app/home/components/index";
import Login from "../app/auth/components/login";
import Logout from "../app/auth/components/logout";
import UserLoginRedirection from "../app/auth/components/userLoginRedirection";
import PrivateRoute from "./privateRoute";
import { PermissionContext } from "../contexts/permissionContext";
import { isEmpty } from "../utils/commonUtils";
import PageNotFound from "../app/pageNotFound/page-not-found";
import ErrorBoundary from "../app/errorBoundary";

const renderRoutes = (routes = []) => {
  if (isEmpty(routes)) return null;
  return routes.map((route1: any, idx: any) => {
    const { link, component, suboptions = [], index = false } = route1;
    return (
      <Route
        key={idx}
        index={index}
        path={link}
        element={
          <Suspense fallback={<ApplicationLoader />}>{component}</Suspense>
        }
      >
        {suboptions.length &&
          suboptions.map((route2: any, idx2: any) => {
            const {
              link: link2,
              component: component2,
              suboptions: suboptions2 = [],
              index: index2 = false,
            } = route2;
            return (
              <Route
                key={idx2}
                index={index2}
                path={link2}
                element={
                  <Suspense fallback={<ApplicationLoader />}>
                    {component2}
                  </Suspense>
                }
              >
                {suboptions2.length &&
                  suboptions2.map((route3: any, idx3: any) => {
                    const { link: link3, component: component3 } = route3;
                    return (
                      <Route
                        key={idx3}
                        path={link3}
                        element={
                          <Suspense fallback={<ApplicationLoader />}>
                            {component3}
                          </Suspense>
                        }
                      />
                    );
                  })}
              </Route>
            );
          })}
      </Route>
    );
  });
};

const AppRoutes = () => {
  const { currentUserRoutes = [] } = useContext(PermissionContext);
  const homeRoutes: any = [];
  const nonHomeRoutes: any = [];
  currentUserRoutes.forEach((route: any) => {
    if (route.excludeNavAndSidebar) {
      nonHomeRoutes.push(route);
    } else {
      homeRoutes.push(route);
    }
  });
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="callback" element={<UserLoginRedirection />} />

      <Route path="/" element={<PrivateRoute>{<Home />}</PrivateRoute>}>
        {renderRoutes(homeRoutes)}
      </Route>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </PrivateRoute>
        }
      >
        {renderRoutes(nonHomeRoutes)}
      </Route>
      {currentUserRoutes.length && (
        <Route
          path="*"
          element={
            <PrivateRoute>
              <PageNotFound />
            </PrivateRoute>
          }
        />
      )}
    </Routes>
  );
};

export default AppRoutes;
