export const DocumentCategoryType = {
  PRE_EXECUTION_DOCUMENTS: "pre_execution_documents",
  EXECUTION_DOCUMENTS: "execution_documents",
  FILINGS_DOCUMENTS: "filings_documents",
  POST_TRANSFER_DOCUMENTS: "post_transfer_documents",
  EXIT_DOCUMENTS: "exit_documents",
};

export const DocumentTypes = {
  onboarding: "Onboarding",
  navdocument: "NAV",
  unitstatement: "Unit_Statements",
  exitdocumet: "Exit_Document",
  tax: "Tax",
  fund: "FUND",
};

export const DocumentTypeNames = [
  {
    label: "Onboarding",
    value: DocumentTypes.onboarding,
  },
  {
    label: "NAV",
    value: DocumentTypes.navdocument,
  },
  {
    label: "Unit_Statements",
    value: DocumentTypes.unitstatement,
  },
  {
    label: "Exit_Document",
    value: DocumentTypes.exitdocumet,
  },
  {
    label: "Tax",
    value: DocumentTypes.tax,
  },
  {
    label: "FUND",
    value: DocumentTypes.fund,
  },
]

export const DocumentCategoryOptions = [
  {
    label: "Pre Execution Documents",
    value: DocumentCategoryType.PRE_EXECUTION_DOCUMENTS,
  },
  {
    label: "Execution Documents",
    value: DocumentCategoryType.EXECUTION_DOCUMENTS,
  },
  {
    label: "Filling Documents",
    value: DocumentCategoryType.FILINGS_DOCUMENTS,
  },
  {
    label: "Post Transfer Documents",
    value: DocumentCategoryType.POST_TRANSFER_DOCUMENTS,
  },
  {
    label: "Exit Documents",
    value: DocumentCategoryType.EXIT_DOCUMENTS,
  },
];

export const DocumentNameType = {
  MOU: "MOU",
  RIGHTS_SHEET: "Rights_Sheet",
  SCHEME_INFO: "Scheme_Info",
  SHA: "SHA",
  SSA: "SSA/CCD/iSAFE/DSA",
  DOA: "DOA",
  SSHA: "SSHA",
  ADDENDUM_TO_SHA: "Addendum_to_SHA",
  ADDENDUM_TO_SSA: "Addendum_to_SSA/CCD/iSAFE/DSA",
  ADDENDUM_TO_SSHA: "Addendum_to_SSHA",
  SIDE_LETTER: "Side_Letter",
  FILED_MGT14: "Filed_MGT14",
  VALUATION_REPORT: "Valuation_Report",
  SIGNED_PAS4: "Signed_PAS4",
  FILED_PAS3: "Filed_PAS3",
  SHARE_CERTIFICATE: "Share_Certificate/CCD_Certificate",
  INVOICE: "Invoice",
  SEBI_TERMSHEET: "SEBI_termsheet",
  MARK_AS_FUNDED_SCREENSHOT: "Marked_as_funded_screenshot",
  ANY_OTHER_DOCUMENT: "Any_Other_Document",
  SHARE_PURCHASE_AGREEMENT: "Share_Purchase_Agreement",
  SHARE_TRANSFER_FORM_SH4: "Share_Transfer_Form_SH-4",
  LEAD_SCHEME_DOCUMENT: "Lead_Scheme_Document",
  INVESTOR_SCHEME_DOCUMENT: "Investor_Scheme_Document",
};

export const DocumentNameOptions = (category: any) => {
  switch (category) {
    case DocumentCategoryType.PRE_EXECUTION_DOCUMENTS:
      return [
        {
          label: "MOU",
          value: DocumentNameType.MOU,
        },
        {
          label: "Rights Sheet",
          value: DocumentNameType.RIGHTS_SHEET,
        },
        {
          label: "Scheme Info",
          value: DocumentNameType.SCHEME_INFO,
        },
        {
          label: "Lead Scheme Document",
          value: DocumentNameType.LEAD_SCHEME_DOCUMENT,
        },
        {
          label: "Investor Scheme Document",
          value: DocumentNameType.INVESTOR_SCHEME_DOCUMENT,
        },
      ];
    case DocumentCategoryType.EXECUTION_DOCUMENTS:
      return [
        {
          label: "SHA",
          value: DocumentNameType.SHA,
        },
        {
          label: "SSA/CCD/iSAFE/DSA",
          value: DocumentNameType.SSA,
        },
        {
          label: "DOA",
          value: DocumentNameType.DOA,
        },
        {
          label: "SSHA",
          value: DocumentNameType.SSHA,
        },
        {
          label: "Addendum to SSHA",
          value: DocumentNameType.ADDENDUM_TO_SSHA,
        },
        {
          label: "Side Letter",
          value: DocumentNameType.SIDE_LETTER,
        },
        {
          label: "Addendum to SHA",
          value: DocumentNameType.ADDENDUM_TO_SHA,
        },
        {
          label: "Addendum to SSA/CCD/iSAFE/DSA",
          value: DocumentNameType.ADDENDUM_TO_SSA,
        },
      ];

    case DocumentCategoryType.FILINGS_DOCUMENTS:
      return [
        {
          label: "Filed MGT14",
          value: DocumentNameType.FILED_MGT14,
        },
        {
          label: "Valuation Report",
          value: DocumentNameType.VALUATION_REPORT,
        },
        {
          label: "Signed PAS4",
          value: DocumentNameType.SIGNED_PAS4,
        },
        {
          label: "Filed PAS3",
          value: DocumentNameType.FILED_PAS3,
        },
      ];

    case DocumentCategoryType.POST_TRANSFER_DOCUMENTS:
      return [
        {
          label: "Share Certificate/CCD Certificate",
          value: DocumentNameType.SHARE_CERTIFICATE,
        },
        {
          label: "Invoice",
          value: DocumentNameType.INVOICE,
        },
        {
          label: "Sebi Termsheet",
          value: DocumentNameType.SEBI_TERMSHEET,
        },
        {
          label: "Marked as Funded Screenshot",
          value: DocumentNameType.MARK_AS_FUNDED_SCREENSHOT,
        },
      ];
    case DocumentCategoryType.EXIT_DOCUMENTS:
      return [
        {
          label: "Share Purchase Agreement",
          value: DocumentNameType.SHARE_PURCHASE_AGREEMENT,
        },
        {
          label: "Share Transfer Form SH4",
          value: DocumentNameType.SHARE_TRANSFER_FORM_SH4,
        },
        {
          label: "Any Other Document",
          value: DocumentNameType.ANY_OTHER_DOCUMENT,
        },
      ];
    default:
      return [];
  }
};

export const BreadCrumbDataDealDocument = {
  stepKey: 1,
  steps: [
    {
      title: "Document Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Deal Documents",
    navigationButton: false,
  },
};

export const BreadCrumbDataInvestorDocument = {
  stepKey: 1,
  steps: [
    {
      title: "Document Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Investor Documents",
    navigationButton: false,
  },
};
