import React, { ReactNode, CSSProperties } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";

export type TopbarProps = {
  /**

   * a node to be rendered in the special component.

   */
  children?: ReactNode;
  /**

   *styling for App Bar.

   */
  AppBarStyles?: CSSProperties;
  /**

   *styling for Tool Bar.

   */
  ToolBarStyles?: CSSProperties;

  /**
   * optional parameter to set position as sticky or fixed
   */
  position?: "fixed" | "sticky" | "relative";
  /**

   * a component to be rendered as ribbon.

   */
  ribbonComponent?: ReactNode;
};

const useStyles = makeStyles({
  topbar: {
    boxShadow: " 0px 4px 30px #73767a29 !important",
    backgroundColor: "#fff !important",
    minHeight: "54px",
    marginLeft: "auto",
    flexDirection: "column",
  },
  toolbar: {
    minHeight: "54px",
  },
});

export function Topbar({
  children,
  AppBarStyles,
  ToolBarStyles,
  position = "sticky",
  ribbonComponent,
}: TopbarProps) {
  const classes = useStyles();
  return (
    <AppBar style={AppBarStyles} className={classes.topbar} position={position}>
      <Toolbar style={ToolBarStyles} className={classes.toolbar}>
        {children}
      </Toolbar>
      {ribbonComponent && ribbonComponent}
    </AppBar>
  );
}
