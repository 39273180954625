import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/v1/admin";


export const fetchLvConfigs = (page: number) => {
  const finalURL =
    API_BASE_URL +
    `/lv_config/?page=${page}`;
  return Axios.get(finalURL);
};

export const updateLvConfigData = (data: any) => {
  const finalURL =
    API_BASE_URL +
    `/lv_config/update_lv_config/`;
  return Axios.post(finalURL, data);
};
