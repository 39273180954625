import { makeStyles } from "@mui/styles";

export const sideMainLayout = {
  marginWhenSideBarOpen: "285px",
  marginWhenSideBarClose: "74px",
};

export default makeStyles({
  homeOnSideBarOpen: {
    marginLeft: sideMainLayout.marginWhenSideBarOpen,
    paddingBottom: "10px",
    transition: "margin 1s ease-in-out",
    marginTop: "50px",
  },
  homeOnSideBarClose: {
    marginLeft: sideMainLayout.marginWhenSideBarClose,
    paddingBottom: "10px",
    transition: "margin 1s ease-in-out",
    marginTop: "50px",
  },
  fieldItem: { padding: "0px 0px 20px 0px" },
  modalContainer: {
    height: "310px",
    width: "500px",
  },
  buttonContainer: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-around",
  },
  breadCrumb: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
