import React, { FC, useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { IconButton, Stack, Tooltip } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import NoteModal from "../modals/NoteModal";

import ApproveRejectModal from "../modals/ApproveRejectModal";
import RejectWithoutMail from "../modals/RejectWithoutMail";
import RejectWithMail from "../modals/RejectWithMail";
import { Link, useNavigate } from "react-router-dom";
import TextButton from "../../../../ui-library/buttons/textButton/TextButton";
import moment from "moment";

export type AwaitingInvestorType = {
  formattedData: any[];
  page: number;
  setPage: (num: number) => void;
  count: number;
};

const AwaitingInvestor: FC<AwaitingInvestorType> = (
  props: AwaitingInvestorType
) => {
  const { setPage, formattedData, page, count } = props;
  const [isNoteMoalOpen, setIsNoteModalOpen] = useState(false);
  const [isApproveRejectModalOpen, setIsApproveRejectModalOpen] =
    useState(false);
  const [isRejectWithoutMailOpen, setIsRejectWithoutMailOpen] = useState(false);
  const [isRejectWithMailOpen, setIsRejectWithMailOpen] = useState(false);

  const [selected, setSelected] = useState<any>();
  const navigate = useNavigate();

  const columns = [
    {
      field: "date",
      align: "center",
      headerName: "Date",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) =>
        params?.row?.date && moment(params?.row?.date).format("MMM DD, YYYY"),
    },
    {
      field: "name",
      headerAlign: "left",
      headerName: "Name",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/user/show-user/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}
          </Link>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "mobile",
      headerName: "Phone No.",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "joined",
      headerName: "Joined",
      headerAlign: "left",
      flex: 1,
      minWidth: 100,
    },
    // {
    //   field: 'complete',
    //   headerName: 'Complete',
    //   flex: 1,
    //   minWidth: 100
    // },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      flex: 6,
      minWidth: 200,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Stack direction="row" gap={1}>
            <TextButton
              onClick={() => {
                setSelected(params.row);
                setIsApproveRejectModalOpen(true);
              }}
            >
              Verify
            </TextButton>

            <IconButton
              sx={{
                color: getColor("action_button", 300),
              }}
              onClick={() => {
                setSelected(params.row);
                setIsNoteModalOpen(true);
              }}
            >
              <Tooltip title="Add/View Notes">
                <DescriptionIcon fontSize="small" />
              </Tooltip>
            </IconButton>

            <IconButton
              sx={{
                color: getColor("action_button", 300),
              }}
              onClick={() => {
                window.open(
                  `mailto:${params.row?.email}?subject=SendMail&body=Description`
                );
              }}
            >
              <Tooltip title="Mail">
                <EmailIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const handleCloseNoteModal = () => {
    setIsNoteModalOpen(false);
  };
  const handleCloseApproveRejectModal = () => {
    setIsApproveRejectModalOpen(false);
  };
  const handleCloseRejectWithoutMail = () => {
    setIsRejectWithoutMailOpen(false);
  };
  const handleOpenRejectWithoutMail = () => {
    setIsRejectWithoutMailOpen(true);
  };

  const handleCloseRejectWithMail = () => {
    setIsRejectWithMailOpen(false);
  };
  const handleOpenRejectWithMail = () => {
    setIsRejectWithMailOpen(true);
  };

  return (
    <div style={{ overflow: "auto" }}>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
      <NoteModal
        isOpen={isNoteMoalOpen}
        handleClose={handleCloseNoteModal}
        selected={selected}
        type="User"
      />
      <ApproveRejectModal
        isOpen={isApproveRejectModalOpen}
        handleClose={handleCloseApproveRejectModal}
        selected={selected}
        handleOpenRejectWithoutMail={handleOpenRejectWithoutMail}
        handleOpenRejectWithMail={handleOpenRejectWithMail}
      />
      <RejectWithoutMail
        isOpen={isRejectWithoutMailOpen}
        handleClose={handleCloseRejectWithoutMail}
        selected={selected}
      />
      <RejectWithMail
        isOpen={isRejectWithMailOpen}
        handleClose={handleCloseRejectWithMail}
        selected={selected}
      />
    </div>
  );
};

export default AwaitingInvestor;
