import { API_STATUS } from "../../../../constants/commonConstants";
import UserManagementManager from "../../managers/userManagementManager";
import { Box, Grid } from "@mui/material";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import MoveToAwaitingModal from "../modals/MoveToAwaitingModal";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import TextButton from "../../../../ui-library/buttons/textButton/TextButton";

export default function NonSebiInvestor() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [value, setValue] = useState("");

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [selected, setSelected] = useState<any>();
  const [isMoveToAwaiting, setIsMoveToAwaiting] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "date",
      headerName: "Applied On",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "onboard",
      headerName: "Onboarding As",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "action",
      headerName: "Action",
      align: "center",
      flex: 6,
      minWidth: 200,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <TextButton
            onClick={() => {
              setSelected(params.row);
              setIsMoveToAwaiting(true);
            }}
          >
            Move to Awaiting
          </TextButton>
        );
      },
    },
  ];

  const handleMoveToAwaiting = () => {};
  const handleCloseMoveToAwaiting = () => {
    setIsMoveToAwaiting(false);
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);

      let data = await userManagementManager.getNonSebiInvestors(value, page);

      console.log(data);
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    getData();
  };

  useEffect(() => {
    getData();
  }, [page]);

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Non SEBI Investor",
      navigationButton: false,
    },
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Name/Email"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>
          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>

      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <>
            <MoveToAwaitingModal
              isOpen={isMoveToAwaiting}
              handleClose={handleCloseMoveToAwaiting}
              selected={selected}
              handleMoveToAwaiting={handleMoveToAwaiting}
            />
            <LvComplexTable
              rows={formattedData}
              columns={columns}
              rowHeight={40}
              headerHeight={40}
              minWidth={750}
              enableAdvancedFiltering
              isUnFilteredDataPresent={!isEmpty(formattedData)}
              enableAutoHeight={true}
              pageSize={20}
              page={page}
              enablePagination
              totalRowCount={count}
              handlePageChange={handlePageChange}
              paginationMode="server"
            />
          </>
        )}
      </div>
    </Box>
  );
}
