import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { useState } from "react";
import UserManagementManager from "../../managers/userManagementManager";
import { toast } from "react-toastify";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { CheckBoxSelect } from "../../../../ui-library/form-elements/CheckBoxSelect";
import { error } from "console";

const initialValues = {
  name: "",
  email: "",
  addPromotion: false,
  onboardingFees: "",
  upfrontDrawdown: "",
  placementFees: "",
};

export default function AddUser() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [addPromotion, setAddPromotion] = useState(false);
  const [onboardingFees, setOnboardingFees] = useState(25000);
  const [upfrontDrawdown, setUpfrontDrawdown] = useState(25000);
  const [placementFees, setPlacementFees] = useState(0);

  const handleAddUser = async () => {
    try {
      const result = await userManagementManager.addUser(
        name,
        email,
        addPromotion,
        onboardingFees,
        upfrontDrawdown,
        placementFees
      );
      if (result?.data) toast.success("User added successfully");
      setName("");
      setEmail("");
      setAddPromotion(false);
      setOnboardingFees(25000);
      setUpfrontDrawdown(25000);
      setPlacementFees(0);
    } catch (error: any) {
      toast.error(error?.detail);
    }
  };
  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Add New User",
      navigationButton: false,
    },
  };
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          touched,
          setTouched,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Stack gap={2} width={"30vw"} paddingTop={2}>
                <InputField
                  label="Full Name"
                  error={errors?.name}
                  id="name"
                  name="name"
                  value={values?.name}
                  required
                />
                <InputField
                  label="Email"
                  error={errors?.email}
                  id="email"
                  name="email"
                  value={values?.email}
                  required
                />
                {/* <Box> */}
                <FormControlLabel
                  label="Add Promotion"
                  id="addPromotion"
                  checked={values?.addPromotion}
                  value={values?.addPromotion}
                  onChange={(e: any) => {
                    setTouched({ ...touched, addPromotion: true });
                    setFieldValue("addPromotion", e.target.checked);
                  }}
                  control={<Checkbox />}
                />
                {/* </Box> */}
                {values?.addPromotion ? (
                  <>
                    {" "}
                    <InputField
                      label={"Onboarding Fee"}
                      error={false}
                      id="onboarding_fee"
                      name="onboarding_fee"
                      value={values?.onboardingFees}
                      type="number"
                    />
                    <InputField
                      label={"Upfront Drawdown"}
                      error={false}
                      id="upfront_drawdown"
                      name="upfront_drawdown"
                      value={values?.upfrontDrawdown}
                      type="number"
                    />
                    <InputField
                      label={"Placement Fee"}
                      error={false}
                      id="placement_fee"
                      name="placement_fee"
                      value={values?.placementFees}
                      type="number"
                    />{" "}
                  </>
                ) : null}
                <Stack direction="row" gap={1} pb={2}>
                  <ActionButton
                    onClick={handleAddUser}
                    disabled={!(isValid && dirty)}
                  >
                    Create User
                  </ActionButton>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
