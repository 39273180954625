import React, { useEffect, useState } from "react";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { Box, Grid } from "@mui/material";
import UserManagementManager from "../../managers/userManagementManager";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import AwaitingPhoneNumber from "./AwaitingPhoneNumber";
import VerifiedPhoneNumber from "./VerifiedPhoneNumber";
import { VERIFY_PHONE_TAB } from "../../constants/VERIFY_INVESTOR_CONSTANTS";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "User Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Verify Phone Number",
    navigationButton: false,
  },
};

export default function VerifyPhoneNumber() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const [value, setValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(VERIFY_PHONE_TAB[0].value);

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(20);

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data;
      switch (selectedTab) {
        case "awaiting":
          data = await userManagementManager.getVerifyingPhoneAwaiting(
            value,
            page,
            "new"
          );
          break;
        case "verified":
          data = await userManagementManager.getVerifyingPhoneVerified(
            value,
            page,
            "new"
          );
          break;
        default:
          break;
      }

      setFormattedData(data?.formattedData ?? []);
      setCount(data?.count ?? 20);

      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getData();
  }, [page, selectedTab]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      <Box>
        <HorizontalTabs
          tabs={VERIFY_PHONE_TAB}
          selectedTab={selectedTab}
          onTabChange={(tab: any) => {
            setValue("");
            setSelectedTab(tab);
          }}
          scrollable={false}
        />
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <>
            {selectedTab === "awaiting" ? (
              <AwaitingPhoneNumber
                formattedData={formattedData}
                page={page}
                setPage={setPage}
                count={count}
              />
            ) : null}
            {selectedTab === "verified" ? (
              <VerifiedPhoneNumber
                formattedData={formattedData}
                page={page}
                setPage={setPage}
                count={count}
              />
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
}
