import { useState, useEffect, useContext, useCallback } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { IconConstant } from "../../../../constants/IconConstants";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";
import StartupManagement from "../../managers/startupManager";
import { AuthContext } from "../../../../contexts/authContext";
import { Checkbox, Stack } from "@mui/material";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Link } from "react-router-dom";

const AwaitingTable = (props: any) => {
  const { sortBy, searchBySector, searchByStartup, trigger } = props;
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [page, setPage] = useState(1);

  const getCaDocStatus = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getInitialScreeningDocs(
          "awaiting",
          page,
          searchByStartup ?? "",
          sortBy?.value ?? "asc",
          searchBySector?.value ?? ""
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleMark = async (id: string, flag: any) => {
    try {
      const result = await startupManagement.initialScreeningMarkAsGood(
        id,
        flag
      );
      toast.success(result?.detail);
      getCaDocStatus();
    } catch (e: any) {
      console.log(e);
      toast.error(e?.detail);
    }
  };

  const handleAceept = async (id: string) => {
    try {
      const result = await startupManagement.initialScreeningAction(id, true);
      toast.success(result?.detail);

      getCaDocStatus();
    } catch (e: any) {
      toast.error(e?.detail);
    }
  };

  const handleReject = async (id: string) => {
    try {
      const result = await startupManagement.initialScreeningAction(id, false);
      toast.success(result?.detail);

      getCaDocStatus();
    } catch (e: any) {
      toast.error(e?.detail);
    }
  };

  const columns = [
    {
      field: "serial_no",
      headerName: "S.NO",
      sortable: false,
      flex: 1,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      sortable: false,
      flex: 2,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/startup/show-startup/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
      minWidth: 200,
    },
    {
      field: "startup_sector",
      headerName: "Startup's Sector",
      sortable: false,
      flex: 2,
      minWidth: 300,
    },
    {
      field: "applied_on",
      headerName: "Applied On",
      sortable: false,
      minWidth: 150,
    },
    {
      field: "mark_as_good",
      headerName: "Mark as Good",
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        const [ch, setCh] = useState(params.row?.mark_as_good);
        return (
          <Checkbox
            checked={ch}
            onChange={(e) => {
              setCh(e.target.checked);
              handleMark(params.row?.id, e.target.checked);
            }}
          />
        );
      },
    },
    {
      field: "referred_type_and_by",
      headerName: "Referred Type - Referred By",
      sortable: false,
      flex: 2,
      minWidth: 300,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 2,
      minWidth: 280,
      align: "center",
      renderCell: (params: any) => {
        return (
          <Stack gap={2} direction="row">
            <OutlinedButton
              customStyles={{
                color: getColor("success", 500),
                borderColor: getColor("success", 500),
                width: "7rem",
              }}
              onClick={() => handleAceept(params.row?.id)}
              tableAction
            >
              Accept
            </OutlinedButton>

            <OutlinedButton
              customStyles={{
                color: getColor("error", 500),
                borderColor: getColor("error", 500),
                width: "7rem",
              }}
              onClick={() => {
                handleReject(params?.row?.id);
              }}
              tableAction
            >
              Reject
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  useEffect(() => {
    getCaDocStatus();
  }, [page, trigger]);

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  return apiStatus == API_STATUS.LOADING ? (
    <TablePlaceholder
      rows={6}
      columns={6}
      rowHeight="40px"
      columnHeaderHeight="40px"
    />
  ) : (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={60}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={10}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
    </div>
  );
};

export default AwaitingTable;
