import { AuthContext } from "../../../../contexts/authContext";
import UserManagementManager from "../../managers/userManagementManager";
import { Box, Grid } from "@mui/material";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { useContext, useState, FC } from "react";

type VerifyModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
};

const VerifyModal: FC<VerifyModalType> = (props) => {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const { isOpen, handleClose, selected } = props;
  const handleSubmit = () => {
    handleClose();
  };

  const [message, setMessage] = useState("Are you sure?");
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="Confirm Verification"
      submitText="&nbsp;&nbsp;Yes&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;No&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      <Box width="20vw">
        <Grid container gap={2}>
          <Box width="100%">
            <Grid item>{message}</Grid>
          </Box>
        </Grid>
      </Box>
    </CommonModal>
  );
};

export default VerifyModal;
