import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { Formik } from "formik";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { RadioButton } from "../../../../ui-library/form-elements/RadioButtonSelect";
import { FileUpload } from "../../../../ui-library/form-elements/file-upload/FileUpload";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

const StartCaGeneration = () => {
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);

  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption(result);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <Formik
        initialValues={{
          name: "",
          email: "",
          demo: true,
          role: "",
          fileList: [],
        }}
        onSubmit={() => {}}
      >
        {({ values }: any) => {
          return (
            <Grid container>
              <Grid item xs={8}>
                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Select Investor</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <LvAutoComplete
                      label="Select Investor User"
                      options={option}
                      setOptions={setOption}
                      selectedObject={selectedVal}
                      setSelectedObject={setSelectedVal}
                      onChange={handleTextChange}
                    />
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Date</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker label="Basic date picker" />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Onboarding Fee( INR )</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <CustomTextField
                      InputLabelProps={{}}
                      customStyles={{}}
                      error={false}
                      id="onboarding_fee"
                      name="onboarding_fee"
                    />{" "}
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Upfront drawdown( INR )</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <CustomTextField
                      InputLabelProps={{}}
                      customStyles={{}}
                      error={false}
                      id="upfront_drawdown"
                      name="upfront_drawdown"
                    />{" "}
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Placement Fee( INR )</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <CustomTextField
                      InputLabelProps={{}}
                      customStyles={{}}
                      error={false}
                      id="placement_fee"
                      name="placement_fee"
                    />{" "}
                  </Grid>
                </Stack>
                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>KYC Verified</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <RadioButton
                      options={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                    />
                  </Grid>
                </Stack>
                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>KRA Verified</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <RadioButton
                      options={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                    />
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Unique ID</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <CustomTextField
                      InputLabelProps={{}}
                      customStyles={{}}
                      error={false}
                      id="unique_id"
                      name="unique_id"
                    />{" "}
                  </Grid>
                </Stack>

                <Stack direction="row" gap={3} mb={3}>
                  <Grid item xs={4}>
                    <Typography>Upload Stamp Paper</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FileUpload
                      customStyles={{ maxWidth: 490 }}
                      placeholder="select a file"
                      helperText="upload your file here"
                      // selectedFile={(file:any) => setFileList(file)}
                      multiple
                      value={values?.fileList ?? []}
                      accept={{
                        "image/svg": [".png", ".svg"],
                        "text/pdf": [".pdf"],
                      }}
                      title="File Upload Dialog Box"
                      hideDocumentVault
                    />
                  </Grid>
                </Stack>
                <Box marginLeft="auto" width="fit-content">
                  <ActionButton>Download PDF</ActionButton>
                </Box>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </div>
  );
};

export default StartCaGeneration;
