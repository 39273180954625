import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  AboutEmailType,
  EMAIL_TYPES,
} from "../../../constants/mailCentralConstant/MailCentralConstant";
import { CustomTextField } from "../../../../../ui-library/form-elements/CustomTextField";
import { FileUpload } from "../../../../../ui-library/form-elements/file-upload/FileUpload";
import { CoreDropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";

const AboutEmail: FC<AboutEmailType> = (props) => {
  const { setSelectedTab } = props;
  return (
    <Box>
      <Grid container>
        <Grid item xs={8}>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Type Of Email</Typography>
            </Grid>
            <Grid item xs={8}>
              <CoreDropdown
                options={EMAIL_TYPES}
                label="Type of Email"
                // value={userType}
                onClickMenuItem={(option: any) => {
                  // setUserType(option);
                }}
              />
            </Grid>
          </Stack>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Name of Campaign</Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField name="name_of_campaign" />
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Subject</Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField name="subject" />
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>From Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField name="from_name" />
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>From Email</Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField name="from_email" />
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Attachment</Typography>
            </Grid>
            <Grid item xs={8}>
              <FileUpload
                customStyles={{ maxWidth: 450 }}
                placeholder="select a file"
                helperText="upload your file here"
                // selectedFile={(file:any) => setFileList(file)}
                multiple
                // value={values?.fileList ?? []}
                accept={{
                  "image/svg": [".png", ".svg"],
                  "text/pdf": [".pdf"],
                }}
                title="File Upload Dialog Box"
                hideDocumentVault
              />
            </Grid>
          </Stack>

          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>CC Email</Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextField name="cc_email" />
            </Grid>
          </Stack>

          <ActionButton
            badgeStyles={{
              display: "block",
              width: "fit-content",
              marginLeft: "auto",
            }}
            onClick={() => setSelectedTab("template")}
          >
            Next
          </ActionButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutEmail;
