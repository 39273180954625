import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { Help } from "@mui/icons-material";
import UserManagementManager from "../../managers/userManagementManager";
import Loader from "../../../loader/loading";
import { AdminActionTypes } from "../../constants/ADMIN_ACTION_TYPES";
import { toast } from "react-toastify";
import useDebounce from "../../../../utils/useDebounce";
import { useStyles } from "../usersPanel/styles";
import NoteModal from "../modals/NoteModal";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { useNavigate, useParams } from "react-router-dom";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { ApplicationLoader } from "../../../../ui-library/loaders/application-loader";

const tabs: any = [
  {
    name: "Add",
    value: "add",
  },
  {
    name: "Modify",
    value: "modify",
  },
  {
    name: "Withdraw",
    value: "withdraw",
  },
];

const currencyOptions = [
  { label: "INR", value: "inr" },
  {
    label: "USD",
    value: "usd",
  },
];

const filter = createFilterOptions<any>();

type QuizParams = {
  id: string;
};

export default function ShowUser() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { id = "" } = useParams<QuizParams>();
  const userManagementManager = new UserManagementManager(authContext);
  const [isNoteMoalOpen, setIsNoteModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.value);

  const classes = useStyles();
  const [selectedStartup, setSelectedStartup] = useState<any>({});
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const [startupText, setStartupText] = useState("");
  const [startupLoading, setStartupLoading] = useState(false);

  const startupTextDeb = useDebounce(startupText, 500);

  const handleSearchStartup = async (value: string) => {
    try {
      let { results } = await userManagementManager.getLookupData(
        value,
        "admin_search_startups"
      );
      let result = results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setStartupOptions(result);
      setStartupLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (startupTextDeb) {
      handleSearchStartup(startupTextDeb);
    } else {
      setStartupOptions([]);
    }
  }, [startupTextDeb]);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const [selectedTags, setSelectedTag] = useState<any>({});
  const [optionHashtag, setOptionHashtag] = useState<any>([]);
  const [loadingHashtag, setLoadingHashtag] = useState(false);

  const [detail, setDetail] = useState<any>({});
  const [onBoardingFee, setOnboardingFee] = useState<any>();
  const [drawdownFee, setDrawdownFee] = useState<any>();
  const [placementFee, setPlacementFee] = useState<any>();
  const [LVFirstCheQ, setLVFirstCheQ] = useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setLVFirstCheQ(event.target.value);
  };

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const result = await userManagementManager.getUserDetail(id);
      if (result.data) {
        setDetail(result.data.result);
        setOnboardingFee(0);
        setDrawdownFee(0);
        setPlacementFee(0);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Something Went Wrong");
      navigate(-1);
    }
  };

  const handleEmailChange = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.CHANGE_EMAIL,
        { email }
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "email changed successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateTag = async () => {
    try {
      let tagString = selectedTags?.map((item: any) =>
        item?.inputValue ? item?.label : item?.value
      );
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.ADD_HASHTAG,
        {
          private_hashtag_tokens: tagString?.join(","),
        }
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "tag updated successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleActivate = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.ACTIVATE,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Activated successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeActivate = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.DEACTIVATE,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "De-Activated successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMarkAsPaid = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.MAKE_PAID,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Mark as Paid successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMarkAsUnPaid = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.MAKE_UNPAID,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Mark as UnPaid successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePublish = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.PUBLISH,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "Published successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnPublish = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        AdminActionTypes.UNPUBLISH,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "UnPublished successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMakePartner = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        // todo
        AdminActionTypes.MAKE_PERMANENT,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "UnPublished successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemovePartner = async () => {
    try {
      const result: any = await userManagementManager.adminAction(
        id,
        // todo
        AdminActionTypes.UNPUBLISH,
        {}
      );
      if (result?.data?.success)
        toast.success(result?.data?.message ?? "UnPublished successfully");
      await getData(id);
    } catch (err) {
      console.log(err);
    }
  };

  const getHashTagOptions = async () => {
    try {
      let result = await userManagementManager.getLookupData(
        "",
        "select_user_hashtags"
      );

      result = result?.private_hashtags.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));

      setOptionHashtag(result);
      setLoadingHashtag(false);
    } catch (err) {
      setLoadingHashtag(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getHashTagOptions();
  }, []);
  useEffect(() => {
    getData(id);
  }, [id]);

  const handleCloseNoteModal = () => {
    setIsNoteModalOpen(false);
  };
  const handleOpenNoteModal = () => {
    setIsNoteModalOpen(true);
  };

  return loading ? (
    <ApplicationLoader fullScreen />
  ) : (
    <Box>
      {isNoteMoalOpen && (
        <NoteModal
          isOpen={isNoteMoalOpen}
          handleClose={handleCloseNoteModal}
          selected={{ id: detail?.user?.user_id, name: detail?.user?.name }}
          type="User"
        />
      )}
      <Grid container rowGap={2}>
        <Grid item xs={3}>
          <Box display="flex" alignItems="center" gap={2}>
            <ArticleIcon />
            <Typography variant="h5">{detail?.user?.name}</Typography>
          </Box>
        </Grid>
        <Grid xs={9} textAlign="end">
          <Box display="inline-flex">
            <Box alignItems="center" gap={2} display="flex">
              <ActionButton onClick={handleOpenNoteModal}>
                View Notes
              </ActionButton>
            </Box>
          </Box>
        </Grid>

        {/* headers */}
        <Grid item xs={4}>
          <Typography variant="h6">Field</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Actions</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ borderWidth: 1 }} />
        </Grid>

        {/* user details */}

        <Grid item xs={4}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{detail?.user?.name}</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Outbound User ?</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {detail?.user_role?.outbound_user ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Email</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{detail?.user?.email}</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            label="email"
            value={email}
            onChange={setEmail}
            id="email"
            name="email"
            customStyles={{ marginBottom: 0.5 }}
          />
          <Button
            variant="text"
            disabled={!email.trim()}
            onClick={handleEmailChange}
          >
            Change Email
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>email confirmed?</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.user?.confirmed_email ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Admin Hashtag</Typography>
        </Grid>
        <Grid item xs={4}>
          <Formik initialValues={{ hashtag: "" }} onSubmit={() => {}}>
            <>
              <Autocomplete
                loading={loadingHashtag}
                fullWidth
                multiple
                className={classes.root}
                options={optionHashtag}
                value={optionHashtag.find(
                  (option: any) => option["value"] === selectedTags?.value
                )}
                popupIcon={
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down"
                  />
                }
                onChange={(event: any, value: any) => {
                  if (typeof value === "string") {
                    setSelectedTag({
                      label: value,
                      value,
                    });
                  } else if (value && value.inputValue) {
                    // Create a new value from the user input

                    setSelectedTag({
                      label: value.inputValue,
                      value: value.inputValue,
                    });
                  } else {
                    setSelectedTag(value);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      label: `#${inputValue}`,
                      value: inputValue,
                      inputValue,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return `Add new tag : #${option.inputValue}`;
                  }
                  // Regular option
                  return option.label;
                }}
                renderTags={(
                  value: readonly {
                    label: string;
                    value: string;
                    inputValue: string;
                  }[],
                  getTagProps
                ) =>
                  value.map(
                    (
                      option: {
                        label: string;
                        value: string;
                        inputValue: string;
                      },
                      index: number
                    ) => (
                      <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    )
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Choose Hashtag:"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    helperText={"Please enter 1 or more character"}
                  />
                )}
                PopperComponent={(props) => {
                  return props?.open ? (
                    <Box className={`${classes.optionsBox}`}>
                      <div
                        className={classes.menuBox}
                        style={{
                          maxHeight: "200px",
                        }}
                      >
                        <>{props.children}</>
                      </div>
                      <div
                        onMouseDown={(event) => event.preventDefault()}
                      ></div>
                    </Box>
                  ) : (
                    <></>
                  );
                }}
                PaperComponent={(props) => {
                  return (
                    <Box
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "none !important",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
              />
            </>
          </Formik>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={handleUpdateTag} variant="text">
            Update Admin Hashtag
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Blocked Sectors</Typography>
        </Grid>
        <Grid item xs={4}>
          <Formik initialValues={{ hashtag: "" }} onSubmit={() => {}}>
            <>
              {/* <Autocomplete
                loading={loading1}
                fullWidth
                className={classes.root}
                options={option1}
                value={option1.find(
                  (option: any) => option["id"] === selectedVal1?.id
                )}
                popupIcon={
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down"
                  />
                }
                onChange={(event: any, value: any) => {
                  setSelectedVal1(value);
                }}
                getOptionLabel={(option: any) => `${option.label}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Enter sectors"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    helperText={"Please enter 1 or more character"}
                  />
                )}
                PopperComponent={(props) => {
                  return props?.open ? (
                    <Box className={`${classes.optionsBox}`}>
                      <div
                        className={classes.menuBox}
                        style={{
                          maxHeight: "200px",
                        }}
                      >
                        <>{props.children}</>
                      </div>
                      <div
                        onMouseDown={(event) => event.preventDefault()}
                      ></div>
                    </Box>
                  ) : (
                    <></>
                  );
                }}
                PaperComponent={(props) => {
                  return (
                    <Box
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "none !important",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
              /> */}
            </>
          </Formik>
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update Sectors</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Phone Number</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.user?.phone_no ? detail?.user?.phone_no : "-"}
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>LinkedIn</Typography>
        </Grid>
        <Grid item xs={4}>
          {detail?.user?.linkedin_url?.includes("http") ? (
            <a href={detail?.user?.linkedin_url} target="_blank">
              {detail?.user?.linkedin_url}
            </a>
          ) : (
            <Typography>{detail?.user?.linkedin_url}</Typography>
          )}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Commitment Action</Typography>
        </Grid>
        <Grid item xs={7}>
          <HorizontalTabs
            tabs={tabs}
            selectedTab={selectedTab}
            onTabChange={(tab: any) => {
              setSelectedTab(tab);
            }}
            scrollable={false}
          />
          {
            <Stack rowGap={1}>
              <Autocomplete
                loading={startupLoading}
                fullWidth
                className={classes.root}
                options={startupOptions}
                value={startupOptions.find(
                  (option: any) => option["value"] === selectedStartup?.value
                )}
                popupIcon={
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down"
                  />
                }
                onChange={(event: any, value: any) => {
                  setSelectedStartup(value);
                  setStartupOptions([]);
                }}
                getOptionLabel={(option: any) => `${option.label}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Startup"
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    onChange={(e) => {
                      setStartupLoading(!!e.target.value);

                      setStartupText(e.target.value);
                    }}
                    helperText={"Please enter 1 or more character"}
                  />
                )}
                PopperComponent={(props) => {
                  return props?.open ? (
                    <Box className={`${classes.optionsBox}`}>
                      <div
                        className={classes.menuBox}
                        style={{
                          maxHeight: "200px",
                        }}
                      >
                        <>{props.children}</>
                      </div>
                      <div
                        onMouseDown={(event) => event.preventDefault()}
                      ></div>
                    </Box>
                  ) : (
                    <></>
                  );
                }}
                PaperComponent={(props) => {
                  return (
                    <Box
                      sx={{
                        "& .MuiAutocomplete-listbox": {
                          maxHeight: "none !important",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
              />
              {selectedTab === "add" && (
                <CoreDropdown options={[]} label="Profile Name" />
              )}
              {selectedTab !== "withdraw" && (
                <>
                  {" "}
                  <Stack direction="row" alignItems="center" gap={2}>
                    <CustomTextField label="amount" />{" "}
                    <Typography>Lakhs</Typography>
                  </Stack>
                  <CoreDropdown options={currencyOptions} label="Currency" />
                </>
              )}

              {selectedTab === "add" && (
                <>
                  <FormControlLabel control={<Checkbox />} label="Public" />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Outside commitment"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Trigger Mail"
                  />{" "}
                </>
              )}
              <Box>
                {selectedTab === "modify" && (
                  <Typography color={getColor("error", 500)}>
                    **Modifying the commitment will not trigger any mail to the
                    investor or their respective startup/follower.
                  </Typography>
                )}
                {selectedTab === "withdraw" && (
                  <Typography color={getColor("error", 500)}>
                    **Withdrawing the commitment will not trigger any mail to
                    the investor or their respective startup/follower.
                  </Typography>
                )}
              </Box>
              <Box ml="auto">
                {selectedTab === "add" ? (
                  <Button variant="outlined">Add Commitment</Button>
                ) : selectedTab === "modify" ? (
                  <Button variant="outlined">Modify Commitment</Button>
                ) : (
                  <Button variant="outlined">Withdraw Commitment</Button>
                )}
              </Box>
            </Stack>
          }
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Activation Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.user?.activated ? "Activated" : "Deactivated"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {!!detail?.user?.activated ? (
            <Button variant="text" onClick={handleDeActivate}>
              Deactivate
            </Button>
          ) : (
            <Button variant="text" onClick={handleActivate}>
              Activate
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Onboarding Fee</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={onBoardingFee}
            onChange={(val: number) => setOnboardingFee(val)}
            type="number"
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Upfront Drawdown</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={drawdownFee}
            onChange={(val: number) => setDrawdownFee(val)}
            type="number"
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Placement Fee</Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={placementFee}
            onChange={(val: number) => setPlacementFee(val)}
            type="number"
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">Update</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Paid Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.user?.is_paid_member ? "paid" : "unpaid"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="text"
            onClick={
              !!detail?.user?.is_paid_member
                ? handleMarkAsUnPaid
                : handleMarkAsPaid
            }
          >
            {!!detail?.user?.is_paid_member ? "Mark as UnPaid" : "Mark as Paid"}{" "}
            &nbsp; {/* todo : tooltip note for unpaid */}
            <Tooltip title="Note: Mark as checked only if the funds are received.">
              <Help fontSize="small" />
            </Tooltip>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Invited</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{!!detail?.user?.invited ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Joined</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{!!detail?.user?.joined_on ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={4}>
          {detail?.user?.joined_on}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Publish Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.user?.published ? "Published" : "UnPublished"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="text"
            onClick={
              !!detail?.user?.published ? handleUnPublish : handlePublish
            }
          >
            {!!detail?.user?.published ? "UnPublished" : "Publish"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* <Grid item xs={2}>
          <Typography>Stealth</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>No</Typography>
        </Grid>
        <Grid item xs={5}>
          <Button variant="text">Make Stealth</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        <Grid item xs={4}>
          <Typography>LV AIF Banner</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Not Yet Agreed</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Is Angel Investor </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!detail?.user_role?.angel_investor ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Referred By:</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Goldy DIY 2</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Category</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Is Institutional Investor </Typography>
        </Grid>
        <Grid item xs={4}>
          {!!detail?.user_role?.institution_investor ? "Yes" : "No"}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Is founder</Typography>
        </Grid>
        <Grid item xs={4}>
          {!!detail?.user_role?.startup_founder ? "Yes" : "No"}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <Typography>Is enthusiast</Typography>
        </Grid>
        <Grid item xs={4}>
          {!!detail?.user_role?.enthusiast ? "Yes" : "No"}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* <Grid item xs={2}>
          <Typography>Is External</Typography>
        </Grid>
        <Grid item xs={5}>
          No
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}
        <Grid item xs={4}>
          <Typography>Is Partner</Typography>
        </Grid>
        <Grid item xs={4}>
          No
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">
            Make Partner &nbsp;{" "}
            <Tooltip title="Mark As Partner For Enabling Partner Dashboard for the investor">
              <Help fontSize="small" />
            </Tooltip>{" "}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* 
        <Grid item xs={2}>
          <Typography>Set LVFirstCheQ Status</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            value={LVFirstCheQ}
            onChange={handleChange}
            displayEmpty
            fullWidth
            sx={{ marginBottom: 0.5 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
          <Button variant="text">save</Button>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={5}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* <Grid item xs={2}>
          <Typography>Unsubscribe From Digest</Typography>
        </Grid>
        <Grid item xs={5}>
          Subscribed On:2023-12-18 03:16:10 +0000
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* <Grid item xs={2}>
          <Typography>Unsubscribe From mailchimp</Typography>
        </Grid>
        <Grid item xs={5}>
          Subscribed On:2023-12-18 03:16:10 +0000
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* <Grid item xs={2}>
          <Typography>Relationship Manager</Typography>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={5}>
          <Select
            value={LVFirstCheQ}
            onChange={handleChange}
            displayEmpty
            fullWidth
            sx={{ marginBottom: 0.5 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
          <Button variant="text">Change relationship manager</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={2}>
          <Typography>Account Manager</Typography>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* <Grid item xs={2}>
          <Typography>AIF Manager </Typography>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={5}>
          <Select
            value={LVFirstCheQ}
            onChange={handleChange}
            displayEmpty
            fullWidth
            sx={{ marginBottom: 0.5 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
          <Button variant="text">Change AIF manager</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* <Grid item xs={2}>
          <Typography>Is Lead Investor</Typography>
        </Grid>
        <Grid item xs={5}>
          No
        </Grid>
        <Grid item xs={5}>
          <Button variant="text">Make Lead Investor</Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        <Grid item xs={4}>
          <Typography>FaaS Status</Typography>
        </Grid>
        <Grid item xs={4}>
          {!!detail?.user?.is_faas ? "Faas" : "Non-FaaS"}
        </Grid>
        <Grid item xs={4}>
          <Button variant="text">
            Mark as Non-FaaS &nbsp;{" "}
            <Tooltip title="Note: Remove as Faas">
              <Help fontSize="small" />
            </Tooltip>{" "}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Is Owner of Private Syndicate?</Typography>
        </Grid>
        <Grid item xs={4}>
          No
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Backer of Private Syndicate?</Typography>
        </Grid>
        <Grid item xs={4}>
          {detail?.angel_investor_data?.backer_private_syndicate?.join(" , ")}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Typography>Private Syndicate Investor Landing Page</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          {" "}
          <Button>
            Give LV Feed Access &nbsp;{" "}
            <Tooltip title="Only if part of PS. If access given, investor will be able to access the whole platform on lv.">
              <Help fontSize="small" />
            </Tooltip>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
}
