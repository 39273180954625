import React, { FC } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { TemplateType } from "../../../constants/mailCentralConstant/MailCentralConstant";
import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";

const Template: FC<TemplateType> = (props) => {
  const { setSelectedTab } = props;
  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={8}>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Full Body</Typography>
            </Grid>
            <Grid item xs={8}>
              <ReactQuill theme="snow" />
            </Grid>
          </Stack>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Body Digest</Typography>
            </Grid>
            <Grid item xs={8}>
              <ReactQuill theme="snow" />
            </Grid>
          </Stack>

          <ActionButton
            badgeStyles={{
              display: "block",
              width: "fit-content",
              marginLeft: "auto",
            }}
            onClick={() => setSelectedTab("confirm")}
          >
            Next
          </ActionButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Template;
