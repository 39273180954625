import {
  getCaDocsByStatus,
  getInvestorPayments,
} from "../services/aifManagementServices";
import { getFormattedDate } from "../../../utils/dateUtils";

type docsByStatus = {
  _id: number;
  user_id: number;
  investor_name: string;
  investor_email: string;
  document_id: string;
  created_date: string;
  last_updated_date: string;
};

type investorPayment = {
  _id: number;
  user_id: number;
  profile_name: string;
  investor_email: string;
  order_type: string;
  amount: number;
  currency: string;
  payment_status: string;
  payment_link: string;
  created_date: string;
  last_updated_date: string;
};

export default class AifManagementManager {
  private getFormattedDocsByStatus = (data: any[]) => {
    let result: docsByStatus[] = [];
    data.forEach((item: any) => {
      result.push({
        _id: item?.id ?? 0,
        user_id: item?.user_id ?? 0,
        investor_name: item?.invitee_urls[0]?.name ?? "",
        investor_email: item?.invitee_urls[0]?.email ?? "",
        document_id: item?.document_id ?? "",
        created_date: getFormattedDate(item?.created_date) ?? "",
        last_updated_date: getFormattedDate(item?.last_updated_date) ?? "",
      });
    });
    return result;
  };

  private getFormattedInvestorPayments = (data: any[]) => {
    let result: investorPayment[] = [];
    data.forEach((item: any) => {
      result.push({
        _id: item?.id ?? 0,
        user_id: item?.investor_informations?.investor?.user?.id ?? 0,
        profile_name: item?.investor_informations?.profile_name ?? "",
        investor_email:
          item?.investor_informations?.investor?.user?.email ?? "",
        order_type: item?.order_type ?? "",
        amount: item?.amount ?? "",
        currency: item?.currency ?? "",
        created_date: getFormattedDate(item?.created_date) ?? "",
        payment_status: item?.payment_status ?? "",
        payment_link: item?.order?.payment_link ?? "",
        last_updated_date: getFormattedDate(item?.last_updated_date) ?? "",
      });
    });
    return result;
  };

  async getCaDocsByStatus(status: string, page: number) {
    try {
      let result = await getCaDocsByStatus(status, page);
      let formattedData = this.getFormattedDocsByStatus(result?.data?.results);
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getInvestorPayments(paymentStatus: string, page: number) {
    try {
      let result = await getInvestorPayments(paymentStatus, page);
      let formattedData = this.getFormattedInvestorPayments(
        result?.data?.results
      );
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }
}
