import { Stack } from "@mui/material";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { FC, useEffect, useState } from "react";
import { RadioButtonSelect } from "../../../../ui-library/form-elements/RadioButtonSelect";
import { DropdownButton } from "../../../../ui-library/buttons/DropdownButton";
import UserManagementManager from "../../managers/userManagementManager";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Formik, Form, Field } from "formik";
import { PhoneNumber } from "../../../../ui-library/form-elements/phone-number/PhoneNumber";
import { Dropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { GenderOptions } from "../../constants/common";
import { ProfileTypeOptions } from "../../constants/common";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  handleOpenRejectWithoutMail: () => void;
  handleOpenRejectWithMail: () => void;
};

const ApproveRejectModal: FC<NoteModalType> = (props) => {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const {
    isOpen,
    handleClose,
    selected,
    handleOpenRejectWithoutMail,
    handleOpenRejectWithMail,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState<any>([]);
  const [cityOptions, setCityOptions] = useState<any>([]);

  const initialValues = {
    name: selected?.name || "",
    email: selected?.email || "",
    phone: selected?.mobile || "",
    linkedin: selected?.linkedin_url || "",
    gender: selected?.gender || "",
    country: selected?.citizenship_country?.[0] || "",
    city: selected?.city
      ? { label: selected?.location, value: selected?.city?.[0]?.id }
      : "",
    profile: selected?.describes_your_profile
      ? {
          label: selected?.describes_your_profile,
          value: selected?.describes_your_profile,
        }
      : "",
    isNetWorth2crore: selected?.netWorth2Cr || "",
  };

  const getLookupOptions = async (search: string, type: string) => {
    try {
      let result = await userManagementManager.getLookupData(search, type);
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      if (type === "locations") setCityOptions(result);
      if (type === "countries") setCountryOptions(result);
    } catch (e: any) {
      console.error(e.message || "Error fetching lookup options");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getLookupOptions("", "countries");
    getLookupOptions("", "locations");
    setIsLoading(false);
  }, [selected]);

  const handleApprove = async (value: any) => {
    console.log("Verified");
  };

  const handleReject = (value: any) => {
    if (value === "Without Sending Mail") {
      handleOpenRejectWithoutMail();
      handleClose();
    } else if (value === "With Sending Mail") {
      handleOpenRejectWithMail();
      handleClose();
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleClose}
      title="Investor Details"
      submitText="Close"
      hideButtons
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Stack gap={2} width={"50vw"} paddingTop={2}>
                <InputField
                  label="Name"
                  required
                  fieldName="name"
                  id="name"
                  value={values?.name}
                  error={!!errors?.name}
                  disabled
                />
                <InputField
                  label="Email"
                  required
                  fieldName="email"
                  id="email"
                  value={values?.email}
                  error={!!errors?.email}
                  disabled
                />
                <Field name="phone">
                  {({ form }: any) => (
                    <PhoneNumber
                      form={form}
                      label="Phone"
                      id="phone"
                      required
                      error={!!errors?.phone}
                      value={values?.phone}
                      onChange={(number: any, country: any, e: any) => {
                        setFieldValue("phone", `+${country}`, true);
                      }}
                      disabled
                    />
                  )}
                </Field>
                <InputField
                  label="Linkedin URL"
                  required
                  fieldName="linkedin"
                  id="linkedin"
                  value={values?.linkedin}
                  error={!!errors?.linkedin}
                  disabled
                />
                <Dropdown
                  label="Gender"
                  required
                  options={GenderOptions}
                  value={values?.gender}
                  error={!!errors?.gender}
                  disabled
                />
                <Dropdown
                  label="Citizenship Country"
                  required
                  options={countryOptions}
                  value={values?.country}
                  error={!!errors?.country}
                  disabled
                />
                <Dropdown
                  label="City"
                  required
                  options={cityOptions}
                  value={values?.city}
                  error={!!errors?.city}
                  disabled
                />
                <Dropdown
                  label="Profile"
                  required
                  options={ProfileTypeOptions}
                  value={values?.profile}
                  error={!!errors?.profile}
                  disabled
                />
                <RadioButtonSelect
                  formLabel="Do you have a net worth of more than 2 CR INR apart from primary residence?"
                  id="isNetWorth2crore"
                  fieldName="isNetWorth2crore"
                  options={[
                    { name: "Yes", value: "yes", disabled: true },
                    { name: "No", value: "no", disabled: true },
                  ]}
                  value={values?.isNetWorth2crore}
                />
                <br />
                <Stack direction="row" gap={1} pb={2} justifyContent="end">
                  <DropdownButton
                    fill
                    buttonText="Verify"
                    options={[
                      { label: "With Sending Mail" },
                      {
                        label: "Without Sending Mail",
                      },
                    ]}
                    onOptionClick={handleApprove}
                  />
                  <DropdownButton
                    buttonText="Reject"
                    options={[
                      { label: "With Sending Mail" },
                      {
                        label: "Without Sending Mail",
                      },
                    ]}
                    onOptionClick={handleReject}
                  />
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </CommonModal>
  );
};

export default ApproveRejectModal;
