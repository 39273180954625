import { AuthContext } from "../../../../contexts/authContext";
import DocumentManagement from "../../managers/documentManager";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import React from "react";
import { useContext } from "react";
import { toast } from "react-toastify";

const PublishModal = (props: any) => {
  const { isOpen, handleClose, selected, reloadData } = props;
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);

  const publish = async () => {
    try {
      const result = await documentManagementManager.publishUnpublish(
        selected?.id,
        !selected?.publish
      );
      toast.success("Published Successfully");
      reloadData();
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleSubmit = async () => {
    await publish();
    handleClose();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="Publish Document"
      submitText="&nbsp;&nbsp;Publish&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Close&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      Do you really want to Publish it?
    </CommonModal>
  );
};

export default PublishModal;
