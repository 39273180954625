import React, { FC, useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import {
  DealConsentOptions,
  INVESTMENT_TYPE,
  INVESTOR_RANKING,
  SCHEME_DOC_STATUS,
  TransferTypeOptions,
} from "../../constants/CrmConstants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import dayjs from "dayjs";
import { format } from "date-fns";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

const useStyles = makeStyles({
  customModalWidth: {
    width: "40rem",
  },
  datePickerWidth: {
    width: "100%",
  },
});

export type CrmDataType = {
  formattedData: any[];
  page: number;
  setPage: (num: number) => void;
  count: number;
  tab: string;
  fetchCrmData: (num: number) => void;
  selectedVal: any;
};

const CrmTable: FC<CrmDataType> = (props: CrmDataType) => {
  const classes = useStyles();
  const {
    setPage,
    formattedData,
    page,
    count,
    tab,
    fetchCrmData,
    selectedVal,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isRestrictLoading, setIsRestrictLoading] = useState(false);
  const [isWithoutGSTLoading, setIsWithoutGSTLoading] = useState(false);
  const [isDrawdownLoading, setIsDrawdownLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<any>({
    investment_type: null,
    ca_doc_creation_date: null,
    investor_ranking: null,
    amount_committed: 0,
    commit_date: null,
    onboarding_fee: null,
    transaction_fee: null,
    scheme_doc_status: null,
    scheme_sent_date: null,
    deal_consent_received: null,
    deal_consent_received_date: null,
    fund_consent_received: null,
    amount_transfer_status: null,
    amount_transfered: 0,
    amount_transfer_date: null,
    amount_transfer_last_date: null,
    transfer_type: null,
    country_id: null,
    comment: null,
  });

  const commitmentManagementManager = new CommitmentManagementManager();

  let columns: any = [
    {
      field: "investor",
      headerName: "Investor Name",
      minWidth: 200,
    },
    {
      field: "profile_investor_name",
      headerName: "Profile Investor Name",
      minWidth: 200,
    },
    {
      field: "ca_doc_status",
      headerName: "CA Doc Status",
      minWidth: 100,
      valueFormatter: (params: any) =>
        params.value != null
          ? !!params.value
            ? "Completed"
            : "Incomplete"
          : params.value,
    },
    {
      field: "aif_direct",
      headerName: "AIF/Direct(I)",
      minWidth: 150,
      valueFormatter: (params: any) => params.value.toUpperCase(),
    },
    {
      field: "ca_doc_creation_date",
      headerName: "CA Doc Creation Date",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "investor_ranking",
      headerName: "Investor Ranking",
      minWidth: 150,
      valueFormatter: (params: any) =>
        params.value !== null && params.value !== ""
          ? "P" + params.value
          : params.value,
    },
    {
      field: "aifManager",
      headerName: "AIF Manager",
      minWidth: 150,
    },
    {
      field: "startup",
      headerName: "Startup Name",
      minWidth: 200,
    },
    {
      field: "startup_scheme_name",
      headerName: "Investment Scheme Name",
      minWidth: 200,
    },
    {
      field: "investment_type",
      headerName: "Round Type",
      minWidth: 200,
      valueFormatter: (params: any) => params.value.toUpperCase(),
    },
    {
      field: "proposed_amount",
      headerName: "Amount Committed (INR/USD)",
      minWidth: 200,
      valueFormatter: (params: any) =>
        `${params.value.amount} ${params.value.currency}`,
    },
    {
      field: "commit_date",
      headerName: "Commit Date",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "onboarding_fee",
      headerName: "Onboarding Fees (INR)",
      minWidth: 200,
    },
    {
      field: "transaction_fee",
      headerName: "Transaction Fees (INR)",
      minWidth: 200,
    },
    {
      field: "total_fees",
      headerName: "Total Fees (Incl. GST)",
      minWidth: 200,
      valueGetter: (params: any) =>
        !!params.row.transfer_type &&
        ["Indian", "NRO"].includes(params.row.transfer_type)
          ? params.row.onboarding_fee +
            params.row.transaction_fee +
            0.18 * params.row.transaction_fee
          : params.row.onboarding_fee + params.row.transaction_fee,
    },
    {
      field: "fund_carry",
      headerName: "Fund Carry %",
      minWidth: 100,
    },
    {
      field: "lead_carry",
      headerName: "Lead Carry %",
      minWidth: 100,
    },
    {
      field: "scheme_doc_status",
      headerName: "Scheme Doc Status",
      minWidth: 150,
    },
    {
      field: "scheme_sent_date",
      headerName: "Scheme Sent Date",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "deal_consent_received",
      headerName: "Deal Consent Received",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "deal_consent_received_date",
      headerName: "Deal Consent Received Date",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "fund_consent_received",
      headerName: "Fund Consent Received",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "amount_transfer_status",
      headerName: "Amount Transfer Status",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "amount_transfered",
      headerName: "Amount Transfered(INR/USD)",
      minWidth: 200,
    },
    {
      field: "amount_transfer_date",
      headerName: "Amount Transfer Date",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "amount_transfer_last_date",
      headerName: "Transfer By",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value ? format(new Date(params.value), "MMMM dd, yyyy") : null,
    },
    {
      field: "transfer_type",
      headerName: "Transfer Type",
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Country Of Transfer",
      minWidth: 200,
    },
    {
      field: "amount_difference",
      headerName: "Difference (INR)",
      minWidth: 200,
      valueGetter: (params: any) =>
        !!params.row.transfer_type &&
        ["Indian", "NRO"].includes(params.row.transfer_type)
          ? params.row.onboarding_fee +
            params.row.transaction_fee +
            0.18 * params.row.transaction_fee +
            params.row.proposed_amount.amount -
            params.row.amount_transfered
          : params.row.onboarding_fee +
            params.row.transaction_fee +
            params.row.proposed_amount.amount -
            params.row.amount_transfered,
    },
    {
      field: "comment",
      headerName: "Comments",
      minWidth: 200,
    },
    {
      field: "whatsapp_alert_sent",
      headerName: "Whatsapp Status",
      minWidth: 200,
      valueFormatter: (params: any) =>
        params.value != null ? (!!params.value ? "Yes" : "No") : params.value,
    },
    {
      field: "whatsapp_alert_stage",
      headerName: "Whatsapp Stage",
      minWidth: 200,
    },
  ];

  if (tab === "progress") {
    columns = [
      ...columns,
      {
        field: "lock",
        headerName: "Lock",
        minWidth: 100,
        renderCell: (params: any) => {
          let lockCondition =
            (params.row.investment_type == INVESTMENT_TYPE.DIRECT.value &&
              !!params.row.amount_transfer_status &&
              !!params.row.amount_transfered &&
              !!params.row.amount_transfer_date) ||
            (params.row.investment_type == INVESTMENT_TYPE.AIF.value &&
              !!params.row.ca_doc_status &&
              !!params.row.deal_consent_received &&
              !!params.row.fund_consent_received &&
              !!params.row.amount_transfer_status &&
              !!params.row.amount_transfered &&
              !!params.row.transfer_type &&
              !!params.row.amount_transfer_date);
          return (
            <ActionButton
              customStyles={{
                backgroundColor: !lockCondition
                  ? getColor("error", 800)
                  : getColor("extra", 3100),
                color: getColor("shades", 0),
                opacity: 0.9,
                "&:hover": {
                  opacity: 1,
                  backgroundColor: !lockCondition
                    ? getColor("error", 800)
                    : getColor("extra", 3100),
                  borderColor: !lockCondition
                    ? getColor("error", 800)
                    : getColor("extra", 3100),
                  boxShadow: "none",
                  cursor: !lockCondition ? "not-allowed" : "pointer",
                },
                borderColor: !lockCondition
                  ? getColor("error", 800)
                  : getColor("extra", 3100),
                minWidth: "auto",
                paddingX: "10px",
                paddingY: "5px",
              }}
              onClick={(e: any) => {
                if (!lockCondition) {
                  e.stopPropagation();
                } else {
                  handleActions({
                    investor_commitment_id: params.row.investor_commitment_id,
                    lock: !params.row.lock,
                  });
                }
              }}
            >
              Lock
            </ActionButton>
          );
        },
      },
      {
        field: "user_whatsapp_switch",
        headerName: "Whatsapp Switch",
        minWidth: 130,
        renderCell: (params: any) => {
          return (
            <ActionButton
              // isLoading={isLoading}
              customStyles={{
                backgroundColor: getColor("extra", 3100),
                color: getColor("shades", 0),
                opacity: !params.row.user_whatsapp_switch ? 0.9 : 0.6,
                "&:hover": {
                  opacity: !params.row.user_whatsapp_switch ? 1 : 0.6,
                  backgroundColor: getColor("extra", 3100),
                  borderColor: getColor("extra", 3100),
                  boxShadow: "none",
                  cursor: params.row.user_whatsapp_switch
                    ? "not-allowed"
                    : "pointer",
                },
                borderColor: getColor("extra", 3100),
                minWidth: "auto",
                paddingX: "10px",
                paddingY: "5px",
              }}
              onClick={() => {
                // setIsLoading(true)
                if (!params.row.user_whatsapp_switch) {
                  handleActions({
                    investor_commitment_id: params.row.investor_commitment_id,
                    user_whatsapp_switch: !params.row.user_whatsapp_switch,
                  });
                }
              }}
            >
              Restrict
            </ActionButton>
          );
        },
      },
      {
        field: "user_gst_switch",
        headerName: "GST Switch",
        minWidth: 160,
        renderCell: (params: any) => {
          return (
            <ActionButton
              customStyles={{
                backgroundColor: getColor("extra", 3100),
                color: getColor("shades", 0),
                opacity: !params.row.user_gst_switch ? 0.9 : 0.6,
                "&:hover": {
                  opacity: !params.row.user_gst_switch ? 1 : 0.6,
                  backgroundColor: getColor("extra", 3100),
                  borderColor: getColor("extra", 3100),
                  cursor: params.row.user_gst_switch
                    ? "not-allowed"
                    : "pointer",
                  boxShadow: "none",
                },
                borderColor: getColor("extra", 3100),
                minWidth: "auto",
                paddingX: "10px",
                paddingY: "5px",
              }}
              onClick={(e: any) => {
                if (!params.row.user_gst_switch) {
                  handleActions({
                    investor_commitment_id: params.row.investor_commitment_id,
                    user_gst_switch: !params.row.user_gst_switch,
                  });
                } else {
                  e.stopPropagation();
                }
              }}
            >
              Without GST
            </ActionButton>
          );
        },
      },

      {
        field: "drawdown_Sent",
        headerName: "Drawdown sent",
        minWidth: 200,
        renderCell: (params: any) => {
          return (
            <ActionButton
              // isLoading={isDrawdownLoading}
              customStyles={{
                backgroundColor: getColor("extra", 3100),
                color: getColor("shades", 0),
                opacity:
                  params.row.ca_doc_status == 0 &&
                  (params.row.ca_doc_creation_date == null ||
                    params.row.ca_doc_creation_date == undefined)
                    ? 0.6
                    : params.row.drawdown_sent == true &&
                      params.row.resend_drawdown_notice == null
                    ? 0.6
                    : params.row.drawdown_sent &&
                      params.row.resend_drawdown_notice
                    ? 0.6
                    : 0.9,
                "&:hover": {
                  opacity:
                    params.row.ca_doc_status == 0 &&
                    (params.row.ca_doc_creation_date == null ||
                      params.row.ca_doc_creation_date == undefined)
                      ? 0.6
                      : params.row.drawdown_sent == true &&
                        params.row.resend_drawdown_notice == null
                      ? 0.6
                      : params.row.drawdown_sent &&
                        params.row.resend_drawdown_notice
                      ? 0.6
                      : 1,
                  backgroundColor: getColor("extra", 3100),
                  borderColor: getColor("extra", 3100),
                  boxShadow: "none",
                  cursor:
                    params.row.ca_doc_status == 0 &&
                    (params.row.ca_doc_creation_date == null ||
                      params.row.ca_doc_creation_date == undefined)
                      ? "not-allowed"
                      : params.row.drawdown_sent == true &&
                        params.row.resend_drawdown_notice == null
                      ? "not-allowed"
                      : params.row.drawdown_sent &&
                        params.row.resend_drawdown_notice
                      ? "not-allowed"
                      : "pointer",
                },
                borderColor: getColor("extra", 3100),
                minWidth: "auto",
                paddingX: "10px",
                paddingY: "5px",
              }}
              onClick={() => {
                // setIsDrawdownLoading(true)
                if (!params.row.drawdown_sent_date) {
                  // handleActions({
                  //   investor_commitment_id: params.row.investor_commitment_id,
                  //   drawdown_sent_date: !params.row.drawdown_sent_date,
                  // });
                }
              }}
            >
              {isDrawdownLoading
                ? "Sending..."
                : params.row.drawdown_sent == true &&
                  params.row.resend_drawdown_notice == null
                ? "Drawdown Sent"
                : params.row.drawdown_sent && params.row.resend_drawdown_notice
                ? "Drawdown Sent"
                : "Send Drawdown"}
            </ActionButton>
          );
        },
      },

      {
        field: "action",
        headerName: "Action",
        minWidth: 120,
        renderCell: (params: any) => {
          return (
            <ActionButton
              customStyles={{
                backgroundColor: getColor("action_button", 200),
                color: getColor("shades", 0),
                opacity: 0.9,
                "&:hover": {
                  opacity: 1,
                  backgroundColor: getColor("action_button", 200),
                  borderColor: getColor("action_button", 200),

                  boxShadow: "none",
                },
                borderColor: getColor("action_button", 200),
                minWidth: "auto",
                paddingX: "10px",
                paddingY: "5px",
              }}
              onClick={() => {
                setIsOpen(true);
                setValue(getModalData(params.row));
              }}
            >
              Edit
            </ActionButton>
          );
        },
      },
    ];
  } else {
    columns = [
      ...columns,
      {
        field: "action",
        headerName: "Action",
        minWidth: 120,
        renderCell: (params: any) => {
          return (
            <ActionButton
              customStyles={{
                backgroundColor: getColor("error", 800),
                color: getColor("shades", 0),
                opacity: 0.9,
                "&:hover": {
                  opacity: 1,
                  backgroundColor: getColor("error", 800),
                  borderColor: getColor("error", 800),

                  boxShadow: "none",
                },
                borderColor: getColor("error", 800),
                minWidth: "auto",
                paddingX: "10px",
                paddingY: "5px",
              }}
              onClick={() => {
                unlockCommit(params.row.investor_commitment_id);
              }}
            >
              Unlock
            </ActionButton>
          );
        },
      },
    ];
  }

  const getModalData = (row: any) => {
    return {
      investor_commitment_id: row.investor_commitment_id,
      investment_type: row.investment_type,
      ca_doc_creation_date: row.ca_doc_creation_date,
      investor_ranking: row.investor_ranking,
      onboarding_fee: row.onboarding_fee,
      transaction_fee: row.transaction_fee,
      scheme_doc_status: row.scheme_doc_status,
      scheme_sent_date: row.scheme_sent_date,
      deal_consent_received: row.deal_consent_received,
      deal_consent_received_date: row.deal_consent_received_date,
      fund_consent_received: row.fund_consent_received,
      comment: row.comment,
      amount_committed: row.proposed_amount.amount,
      amount_transfer_status: row.amount_transfer_status,
      amount_transfered: row.amount_transfered,
      amount_transfer_date: row.amount_transfer_date,
      amount_transfer_last_date: row.amount_transfer_last_date,
      transfer_type: row.transfer_type,
      country_id: row.country_id,
      currency: row.proposed_amount.currency,
    };
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const unlockCommit = async (investor_commitment_id: any) => {
    const result = await commitmentManagementManager.unlockCommit(
      investor_commitment_id
    );
    if (result.success) {
      toast.success(result?.message);
      fetchCrmData(selectedVal);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleActions = async (data: any) => {
    const result = await commitmentManagementManager.updateCrmData(data);
    if (result.success) {
      // setIsLoading(false)
      toast.success(result?.message);
      fetchCrmData(selectedVal);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleModalClose = () => {
    setIsSubmitLoading(false);
    setIsOpen(false);
  };
  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    let cleanData = { ...value };
    delete cleanData["amount_committed"];
    delete cleanData["currency"];

    const result = await commitmentManagementManager.updateCrmData({
      ...cleanData,
      proposed_amount: {
        amount: value.amount_committed,
        currency: value.currency,
      },
    });
    if (result.success) {
      toast.success(result?.message);
      fetchCrmData(selectedVal);
      setIsOpen(false);
    } else {
      toast.error("Something went wrong");
    }
    setIsSubmitLoading(false);
  };

  const handleTransactionFee = (data: any) => {
    setValue({ ...value, transaction_fee: data });
  };
  const handleOnboardingFee = (data: any) => {
    setValue({ ...value, onboarding_fee: data });
  };

  return (
    <div>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={60}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={30}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
        leftPinnedColumns={["investor"]}
        rightPinnedColumns={["action"]}
        defaultSortColumn={{ field: "investor_commitment_id", sort: "asc" }}
      />
      <CommonModal
        isOpen={isOpen}
        handleClose={handleModalClose}
        title="Edit commit tracker details"
        isLoading={isSubmitLoading}
        cancelText="Cancel"
        submitText="Submit"
        handleSubmit={handleSubmit}
        muiClasses={{
          paper: classes.customModalWidth,
        }}
      >
        {/* AiF/Direct - dropdown
         CA Doc creation date - date picker
          Investor  ranking - dropdown 
          Amount Committed - Number input
           Onboarding Fees
        Transaction Fees
         Scheme doc Status -dropdown 
         Scheme sent date - date picker 
         Deal consent received - dropdown 
         Deal consent received date -  date picker 
         Fund consent received - dropdown 
         Amount transfer status -  dropdown 
         Amount (INR/USD) - input 
         Amount transfer date - date picker
        Transfer by - date picker
         Transfer type - dropdown 
         Country of transfer - dropdown 
         comments - normal input */}
        {/* {value?.investment_type} */}
        <Grid container>
          <Grid item container direction="column" spacing={3}>
            <Grid item>
              <CoreDropdown
                label="AIF/Direct"
                options={Object.values(INVESTMENT_TYPE)}
                onClickMenuItem={(option: any) => {
                  setValue({ ...value, investment_type: option.value });
                }}
                defaultValue={Object.values(INVESTMENT_TYPE).find(
                  (item) => item.value === value.investment_type
                )}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="CA Doc Creation Date"
                  value={dayjs(value?.ca_doc_creation_date)}
                  onChange={(newValue: any) =>
                    setValue({ ...value, ca_doc_creation_date: newValue })
                  }
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Investor Ranking"
                options={Object.values(INVESTOR_RANKING)}
                onClickMenuItem={(option: any) => {
                  setValue({ ...value, investor_ranking: option.value });
                }}
                defaultValue={
                  Object.values(INVESTOR_RANKING).find(
                    (item) => item.value === value.investor_ranking
                  ) ||
                  Object.values(INVESTOR_RANKING).find(
                    (item) => item.label === INVESTOR_RANKING.NA.label
                  )
                }
              />
            </Grid>
            <Grid item>
              <CustomTextField
                label={"Amount Committed (INR/USD)"}
                error={false}
                value={value.amount_committed}
                onChange={(val: any) =>
                  setValue({ ...value, amount_committed: val })
                }
                id="amount_committed"
                name="amount_committed"
              />
              {/* {value?.currency} */}
            </Grid>
            <Grid item>
              <CustomTextField
                label={"Onboarding Fees (INR)"}
                error={false}
                value={value.onboarding_fee}
                onChange={handleOnboardingFee}
                id="onboarding_fee"
                name="onboarding_fee"
              />
            </Grid>
            <Grid item>
              <CustomTextField
                label={"Transaction Fees (INR)"}
                error={false}
                value={value.transaction_fee}
                onChange={handleTransactionFee}
                id="transaction_fee"
                name="transaction_fee"
              />
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Scheme Doc Status"
                options={Object.values(SCHEME_DOC_STATUS)}
                onClickMenuItem={(option: any) => {
                  setValue({ ...value, scheme_doc_status: option.value });
                }}
                defaultValue={
                  Object.values(SCHEME_DOC_STATUS).find(
                    (item) => item.value === value.scheme_doc_status
                  ) ||
                  Object.values(SCHEME_DOC_STATUS).find(
                    (item) => item.label === SCHEME_DOC_STATUS.NA.label
                  )
                }
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Scheme Sent Date"
                  value={dayjs(value?.scheme_sent_date)}
                  onChange={(newValue: any) =>
                    setValue({ ...value, scheme_sent_date: newValue })
                  }
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Deal Consent Received"
                options={DealConsentOptions}
                onClickMenuItem={(option: any) => {
                  setValue({ ...value, deal_consent_received: option.value });
                }}
                defaultValue={Object.values(DealConsentOptions).find(
                  (item) => item.value === value?.deal_consent_received
                )}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Deal Consent Received Date"
                  value={dayjs(value?.deal_consent_received_date)}
                  onChange={(newValue: any) =>
                    setValue({ ...value, deal_consent_received_date: newValue })
                  }
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item>
              <CoreDropdown
                label="Fund Consent Received"
                options={DealConsentOptions}
                onClickMenuItem={(option: any) => {
                  setValue({ ...value, fund_consent_received: option.value });
                }}
                defaultValue={Object.values(DealConsentOptions).find(
                  (item) => item.value === value?.fund_consent_received
                )}
              />
            </Grid>

            <Grid item>
              <CoreDropdown
                label="Amount Transfer Status"
                options={DealConsentOptions}
                onClickMenuItem={(option: any) => {
                  setValue({ ...value, amount_transfer_status: option.value });
                }}
                value={DealConsentOptions?.find(
                  (item: any) => item.value === value?.amount_transfer_status
                )}
              />
            </Grid>

            <Grid item>
              <CustomTextField
                label={"Amount Transfered (INR/USD)"}
                error={false}
                value={value.amount_transfered}
                onChange={(val: any) =>
                  setValue({ ...value, amount_transfered: val })
                }
                id="amount_transfered"
                name="amount_transfered"
              />
            </Grid>

            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Amount Transfer Date"
                  value={dayjs(value?.amount_transfer_date)}
                  onChange={(newValue: any) =>
                    setValue({ ...value, amount_transfer_date: newValue })
                  }
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Transfer By"
                  value={dayjs(value?.amount_transfer_last_date)}
                  onChange={(newValue: any) =>
                    setValue({ ...value, amount_transfer_last_date: newValue })
                  }
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <CoreDropdown
                label="Transfer Type"
                options={TransferTypeOptions}
                onClickMenuItem={(option: any) =>
                  setValue({ ...value, transfer_type: option.label })
                }
                defaultValue={Object.values(TransferTypeOptions).find(
                  (item) => item.label === value.transfer_type
                )}
              />
            </Grid>

            <Grid item>
              <CoreDropdown
                label="Country of Transfer"
                options={[]}
                onClickMenuItem={(option: any) =>
                  setValue({ ...value, country_of_transfer: option })
                }
                value={value?.country_of_transfer}
              />
            </Grid>

            <Grid item>
              <CustomTextField
                label="Comments"
                onChange={(val: string) => setValue({ ...value, comment: val })}
                value={value?.comment}
              />
            </Grid>
          </Grid>
        </Grid>
      </CommonModal>
    </div>
  );
};

export default CrmTable;
