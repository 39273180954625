import React, { ReactNode } from "react";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import { getColor } from "../../Styles/Colors";
import { makeStyles } from "@mui/styles";

type variant = "text" | "contained" | "outlined";

export type ActionButtonProps = {
  /**
   * Renders any ReactNode inside itself
   */
  children?: ReactNode;
  /**
   * enable/disable the button
   */
  disabled?: boolean;
  /**
   * show/hide spinner
   */
  isLoading?: boolean;
  /**
   * onClick handler function
   */
  onClick?: Function;
  /**
   * A custom spinner element that can be shown instead of the default spinner
   */
  customSpinner?: ReactNode;
  /**
   * custom styling of the button. Accepts styling for MUI sx prop
   */
  customStyles?: object;

  variant?: variant;

  type?: any;

  /**
   * The form attribute specifies the form the button belongs to
   */
  form?: string;
  /**
   * showBadge enables red badge over the button
   */
  showBadge?: boolean;
  /**
   * prop to hide the button
   */
  hidden?: boolean;
  /**
   * ref to the button
   */
  buttonRef?: any;
  /**
   * prop for styling badge when enabled
   */
  badgeStyles?: any;
};

const useStyles = makeStyles({
  badge: {
    height: "11px !important",
    width: "11px !important",
    borderRadius: "50% !important",
    background: `${getColor("error", 800)} ! important`,
    marginTop: "2px !important",
    marginRight: "2px !important",
  },
});

export function ActionButton({
  children,
  disabled = false,
  isLoading = false,
  customSpinner = null,
  onClick,
  customStyles = {},
  type = "button",
  variant = "contained",
  form = "",
  showBadge = false,
  hidden = false,
  buttonRef = null,
  badgeStyles = {},
}: ActionButtonProps) {
  const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(event);
  };
  const classes = useStyles();

  if (hidden) {
    return <div />;
  }

  return (
    <Badge
      variant="dot"
      invisible={!showBadge}
      classes={{ badge: classes.badge }}
      sx={{ ...badgeStyles }}
    >
      <Button
        ref={buttonRef}
        type={type}
        data-testid="action-btn"
        disabled={disabled}
        variant={variant}
        onClick={handleChange}
        form={form}
        sx={{
          backgroundColor: getColor("action_button", 300),
          height: "36px",
          color: getColor("shades", 0),
          borderRadius: "4px",
          textAlign: "center",
          letterSpacing: "1px",
          "&:hover": {
            backgroundColor: getColor("action_button", 400),
            borderColor: getColor("action_button", 0),
            boxShadow: "none",
          },
          ...customStyles,
        }}
      >
        {isLoading ? (
          customSpinner ? (
            customSpinner
          ) : (
            <CircularProgress size={20} style={{ marginRight: 5 }} />
          )
        ) : null}
        {children}
      </Button>
    </Badge>
  );
}
