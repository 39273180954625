import React, { ReactNode } from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Box,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getColor } from "../Styles/Colors";

const useStyles = makeStyles({
  pageHeader: {
    fontSize: "1.25rem !important",
    letterSpacing: "1px !important",
    fontWeight: "700 !important",
    lineHeight: "1.875rem !important",
    color: getColor("neutral", 900),
  },
  navIcon: { marginRight: "0.4rem", cursor: "pointer" },
  step: {
    fontWeight: "500 !important",
    fontSize: "0.8125rem !important",
    lineHeight: "1.25rem !important",
  },
  nonHighlighted: {
    color: getColor("background", 1500),
  },
  highlighted: {
    color: getColor("background", 900),
  },
  infoIcon: {
    marginLeft: "8px",
    marginTop: "2px",
    height: "14px !important",
    width: "14px !important",
  },
  separator: {
    color: getColor("primary", 500),
  },
  tooltipArrow: {
    "&:before": { backgroundColor: `${getColor("shades", 0)} !important` },
    fontSize: "0.9rem !important",
    left: "4px !important",
  },
  tooltip: {
    backgroundColor: `${getColor("shades", 0)} !important`,
    color: `${getColor("shades", 100)} !important`,
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.4) !important",
    padding: "16px !important",
    right: "8px !important",
  },
  tooltipImage: {
    display: "inline",
    paddingLeft: "8px",
    verticalAlign: "text-bottom",
  },
});

type stepsObject = {
  /**
   * add the title of breadcrumb in top part
   */
  title: string | ReactNode;
  /**
   * whether to display or not
   */
  titleDisplay: boolean;
};

type pageHeaderObject = {
  /**
   * main header in the below part
   */
  header: string | ReactNode;
  /**
   * back button
   */
  navigationButton?: boolean;
  /**
   * back button function, here we have to set the pageHeader object which will be same as where we want to go back
   */
  navigationFunction?: any;
  /**
   * pass tooltip in info icon
   */
  helpText?: string;
};

export type BreadcrumbProps = {
  /**
   * this is an array to show the steps in the breadcrum, we can update it by adding some click function
   */
  steps: stepsObject[];
  /**
   * the current header we want to show
   */
  pageHeader: pageHeaderObject;
  /**
   * this is used to maintain the level of the breadcrumb, default is 1
   */
  stepKey?: number;
  /**
   * if true, shows separator between the breadcrumbs
   */
  breadcrumbSeparator?: boolean;
};

export function Breadcrumb({
  steps,
  pageHeader: {
    header,
    navigationButton = false,
    navigationFunction,
    helpText,
  },
  stepKey = 1,
  breadcrumbSeparator = false,
}: BreadcrumbProps) {
  const classes = useStyles();
  return (
    <Box mb={2} id="breadcrumb">
      <Stack spacing={2}>
        <MUIBreadcrumbs
          aria-label="breadcrumb"
          separator={breadcrumbSeparator ? "/" : ""}
          classes={{ separator: classes.separator }}
        >
          {steps.map((step: any, index: any) => (
            <div key={`breadcrumb-${index}`}>
              {stepKey > index ? (
                <Typography
                  className={`${classes.step} ${
                    index === steps.length - 1 && index !== 0
                      ? classes.highlighted
                      : classes.nonHighlighted
                  }`}
                >
                  {step.title}
                </Typography>
              ) : null}
            </div>
          ))}
        </MUIBreadcrumbs>
        <Box display="flex" justifyContent="space-between" mt={"0 !important"}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {navigationButton ? (
              <button
                style={{ background: "none", border: "none" }}
                onClick={navigationFunction}
                className={classes.navIcon}
              >
                <img
                  alt="back icon"
                  src="https://dsjvxb1plg419.cloudfront.net/v2.0/blue-back-icon.svg"
                />
              </button>
            ) : null}
            <Typography className={classes.pageHeader}>{header}</Typography>
            {helpText && (
              <Tooltip
                title={helpText}
                placement="top-start"
                arrow
                classes={{
                  arrow: classes.tooltipArrow,
                  tooltip: classes.tooltip,
                }}
                sx={{ position: "relative" }}
              >
                <img
                  data-testid="info-tooltip"
                  src="https://dsjvxb1plg419.cloudfront.net/v2.0/InfoBlueIcon.svg"
                  alt="info"
                  className={classes.tooltipImage}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
