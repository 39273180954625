import { createTheme } from "@mui/material";

const theme = createTheme({
  spacing: 10,
  palette: {
    text: {
      primary: "#293847",
    },
  },
  typography: {
    fontFamily: "Work Sans",
    fontSize: 14,
    display_01_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "72px",
      fontWeight: 500,
    },
    display_01_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "72px",
      fontWeight: 700,
    },
    display_01_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "72px",
      fontWeight: 800,
    },
    display_02_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "60px",
      fontWeight: 500,
    },
    display_02_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "60px",
      fontWeight: 700,
    },
    display_02_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "60px",
      fontWeight: 800,
    },
    heading_01_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "48px",
      fontWeight: 500,
    },
    heading_01_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "48px",
      fontWeight: 700,
    },
    heading_01_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "48px",
      fontWeight: 800,
    },
    heading_01_small_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "34px",
      fontWeight: 500,
    },
    heading_01_small_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "34px",
      fontWeight: 700,
    },
    heading_01_small_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "34px",
      fontWeight: 800,
    },
    heading_02_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "24px",
      fontWeight: 500,
    },
    heading_02_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "24px",
      fontWeight: 700,
    },
    heading_02_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "24px",
      fontWeight: 800,
    },
    heading_03_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "20px",
      fontWeight: 500,
    },
    heading_03_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "20px",
      fontWeight: 700,
    },
    heading_03_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "20px",
      fontWeight: 800,
    },
    heading_04_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "16px",
      fontWeight: 500,
    },
    heading_04_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "16px",
      fontWeight: 700,
    },
    heading_04_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "16px",
      fontWeight: 800,
    },
    heading_05_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "14px",
      fontWeight: 500,
    },
    heading_05_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "14px",
      fontWeight: 700,
    },
    heading_05_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "14px",
      fontWeight: 800,
    },
    heading_06_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "12px",
      fontWeight: 500,
    },
    heading_06_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "12px",
      fontWeight: 700,
    },
    heading_06_extra_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "12px",
      fontWeight: 800,
    },
    heading_07_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "18px",
      fontWeight: 600,
    },
    sub_heading_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "16px",
      fontWeight: 400,
    },
    sub_heading_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "16px",
      fontWeight: 500,
    },
    paragraph_01_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "20px",
      fontWeight: 400,
    },
    paragraph_01_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "20px",
      fontWeight: 500,
    },
    paragraph_02_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "16px",
      fontWeight: 400,
    },
    paragraph_02_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "16px",
      fontWeight: 500,
    },
    paragraph_03_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "12px",
      fontWeight: 400,
    },
    paragraph_03_bold: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "12px",
      fontWeight: 500,
    },
    caption_regular: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "11px",
      fontWeight: 400,
    },
    caption_medium: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "11px",
      fontWeight: 500,
    },
    caption_uppercase: {
      fontFamily: "Work Sans",
      display: "block",
      fontSize: "11px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
});

export { theme };
