import { FC, useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { FileUpload } from "../../../../../ui-library/form-elements/file-upload/FileUpload";
import { CoreDropdown } from "../../../../../ui-library/dropdowns/core-dropdown";
import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../../../../../contexts/authContext";
import Loader from "../../../../loader/loading";
import { useStyles } from "../../dealDocuments/styles";
import { toast } from "react-toastify";
import { DocumentTypeNames } from "../../../constants/documentConstants";
import DocumentManagement from "../../../managers/documentManager";

type ReactBulkUploadModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
};

const BulkUploadModal: FC<ReactBulkUploadModalType> = (props) => {
  const { isOpen, handleClose } = props;
  console.log("PROPS", props);
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(authContext);
  const [files, setFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<any>(null);
  const [selectedYear, setSelectedYear] = useState<any>(null);

  const DocumentCategoryOptionsExtended = [
    { label: "All", value: "all" },
    ...DocumentTypeNames,
  ];

  const yearOptions = [
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const data = {
        typeOfFile: "",
        year: selectedYear,
        quarter: "",
        file: files[0],
      };

      const result = await documentManagementManager.uploadBulkDocument(data);
      toast.success("Document Uploaded Successfully");
      setIsLoading(false);
      handleClose();
    } catch (error: any) {
      toast.error(error.message ?? "Something Went Wrong");
      setIsLoading(false);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title="Bulk Upload Document"
      submitText="&nbsp;&nbsp;Submit&nbsp;&nbsp;"
      cancelText="&nbsp;&nbsp;Close&nbsp;&nbsp;"
      buttonStyles={{ width: "min-width" }}
    >
      <ToastContainer />
      <Box width="50vw">
        {isLoading && <Loader />}
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Type of File
            </Grid>
            <Grid item xs={8}>
              <Box width={"190px"}>
                <CoreDropdown
                  label="Choose File Type"
                  options={DocumentCategoryOptionsExtended}
                  value={selectedFileType}
                  onClickMenuItem={(value: any) => setSelectedFileType(value)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Select Year
            </Grid>
            <Grid item xs={8}>
              <Box width={"190px"}>
                <CoreDropdown
                  label="Choose Year"
                  options={yearOptions}
                  value={selectedYear}
                  onClickMenuItem={(value: any) => setSelectedYear(value)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Select File
            </Grid>
            <Grid item xs={8}>
              <FileUpload
                customStyles={{ maxWidth: 550 }}
                placeholder="select a file"
                helperText="upload your file here"
                selectedFile={(file: any) => setFiles(file)}
                multiple
                value={files}
                accept={{ "image/svg": [".png", ".svg"], "text/pdf": [".pdf"] }}
                title="File Upload Dialog Box"
                hideDocumentVault
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default BulkUploadModal;
