import * as Axios from "../../../utils/apiUtils";

const API_BASE_URL = "https://api.staging.letsventure.com/v1";

export const getDealDocuments = (
  page: number,
  deal_id: string = "",
  document_category: string = "",
  document_name: string = ""
) => {
  // const finalURL =
  //   API_BASE_URL +
  //   `/document_vault/startup/?admin_view=true&page=${page}&startup_id=${deal_id}&document_category=${document_category}&document_name=${document_name}`;
  
    const params: { [key: string]: string | number | boolean | undefined } = {
      admin_view: true,
      page,
      document_category: document_category,
      document_name: document_name,
      startup_id: deal_id,
    };
    const queryString = Object.entries(params)
      .reduce<string[]>((acc, [key, value]) => {
        if (value != null && value != "") {
          acc.push(`${key}=${encodeURIComponent(String(value))}`);
        }
        return acc;
      }, [])
      .join('&');
  
    const finalURL = `${API_BASE_URL}/document_vault/startup/?${queryString}`;
    return Axios.get(finalURL);
};

export const getInvestorDocuments = (
  page: number,
  user_id?: string,
  investor_information_id?: string,
  document_title?: string
) => {
  // const finalURL =
  //   API_BASE_URL +
  //   `/document_vault/investor/?admin_view=true&page=${page}&user_id=${investor_id}&investor_information_id=${investor_information_id}&document_title=${document_title}`;
  // return Axios.get(finalURL);
  const params: { [key: string]: string | number | boolean | undefined } = {
    admin_view: true,
    page,
    user_id: user_id,
    investor_information_id,
    document_title,
  };

  const queryString = Object.entries(params)
    .reduce<string[]>((acc, [key, value]) => {
      if (value != null && value != "") {
        acc.push(`${key}=${encodeURIComponent(String(value))}`);
      }
      return acc;
    }, [])
    .join('&');

  const finalURL = `${API_BASE_URL}/document_vault/investor/?${queryString}`;
  return Axios.get(finalURL);
};

export const handleDocStatus = (documentId: number, data: object) => {
  const finalURL = API_BASE_URL + `/document_vault/investor/${documentId}/`;
  return Axios.patch(finalURL, data);
};

export const uploadBulkDocument = (data: any) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const finalURL = API_BASE_URL + `/document_vault/investor/bulk_upload_docs/`;
  return Axios.post(finalURL, data, config);
};

export const uploadDocument = (data: any) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const finalURL = API_BASE_URL + `/document_vault/investor/`;
  return Axios.post(finalURL, data, config);
};

export const searchDealName = (search: string) => {
  const finalURL =
    API_BASE_URL +
    `/admin/lookups/get_lookup_data/?type=admin_search_startups&q=${search}`;
  return Axios.get(finalURL);
};

export const uploadDealDocument = (data: any) => {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const finalURL = API_BASE_URL + `/document_vault/startup/`;
  return Axios.post(finalURL, data, config);
};

export const downloadDealDocument = (
  page: number,
  startup_id: string,
  document_category: string,
  document_name: string
) => {
  const finalURL =
    API_BASE_URL +
    `/document_vault/startup/download_csv/?admin_view=true&page=${page}&startup_id=${startup_id}&document_category=${document_category}&document_name=${document_name}`;
  return Axios.get(finalURL);
};

export const publishUnpublish = (id: string, data: any) => {
  const finalURL = API_BASE_URL + `/document_vault/startup/${id}/`;
  return Axios.patch(finalURL, data);
};
