import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import React, { ReactNode } from "react";
import { makeStyles } from "@mui/styles";
import {
  cancelButton,
  modalStyle,
  actions,
  handleSubmitButton,
  titleSection,
  childrenContainer,
} from "./common-modal-styles";
import { OutlinedButton } from "../buttons/OutlinedButton";
import { ActionButton } from "../buttons/ActionButton";

export type CommonModalProps = {
  /**
   * childern prop
   */
  children: any;
  /**
   * isOpen is a boolean prop to open the modal if true
   */
  isOpen: boolean;
  /**
   * you can pass jsx as well in title
   */
  title?: any;
  /**
   * closeIcon is a boolean prop for close option in modal
   */
  closeIcon?: boolean;
  /**
   * hideButtons is a boolean prop to hide buttons in modal
   */
  hideButtons?: boolean;
  /**
   * disableSubmitButton is a boolean prop to disable button in modal
   */
  disableSubmitButton?: boolean;
  /**
   * isLoading is a boolean prop to disable the submit button of modal if true and you can handle loading events
   */
  isLoading?: boolean;
  /**
   * cancelText is a string prop for cancel button text of modal
   */
  cancelText?: string;
  /**
   * submitText is a string prop for submit button text of modal
   */
  submitText?: string;
  /**
   * handleSubmit is a function which called when submit button clicked
   */
  handleSubmit?: any;
  /**
   * handleClose is a function which called when close or cancel button clicked
   */
  handleClose: any;
  /**
   * formName is an optional string for which the submit button should work
   */
  formName?: any;
  /**
   * add custom mui classes
   */
  muiClasses?: any;
  /**
   * add text at the end of modal
   */
  endText?: any;
  /**
   * only hide cancel button
   */
  hideCancelButton?: boolean;
  /**
   * custom button styles
   */
  buttonStyles?: any;
  /**
   * custom modal styles
   */
  customStyles?: any;
};

const useStyles = makeStyles({
  submitContainer: {
    "& .MuiBadge-root": {
      width: "100%",
      paddingLeft: "12px",
    },
  },
  title: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
});

export const CommonModal = ({
  children,
  isOpen,
  handleClose,
  title,
  isLoading,
  hideButtons = false,
  disableSubmitButton = false,
  hideCancelButton = false,
  cancelText,
  submitText,
  handleSubmit,
  closeIcon = false,
  formName,
  muiClasses = {},
  endText = "",
  buttonStyles = null,
  customStyles = {},
}: CommonModalProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="common-modal"
      sx={{ ...modalStyle, ...customStyles }}
      classes={{
        ...muiClasses,
      }}
    >
      {title && (
        <DialogTitle sx={titleSection}>
          <div className={classes.title}>{title}</div>
          {closeIcon && (
            <IconButton onClick={handleClose}>
              <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/CloseIcon.svg" />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent sx={childrenContainer}>{children}</DialogContent>
      {!hideButtons && (
        <Box pb="30px">
          <DialogActions sx={actions}>
            <Grid container justifyContent={"end"}>
              <Grid item xs={buttonStyles?.width ? false : 6}>
                {!hideCancelButton && (
                  <OutlinedButton
                    disabled={isLoading}
                    customStyles={{ ...cancelButton, ...buttonStyles }}
                    onClick={handleClose}
                  >
                    {cancelText ?? "CANCEL"}
                  </OutlinedButton>
                )}
              </Grid>
              <Grid item xs={buttonStyles?.width ? false : 6}>
                <div className={classes.submitContainer}>
                  <ActionButton
                    isLoading={isLoading}
                    disabled={disableSubmitButton}
                    // customStyles={{ ...handleSubmitButton, ...buttonStyles }}
                    onClick={handleSubmit}
                    form={formName}
                    type="submit"
                  >
                    {submitText ?? "SUBMIT"}
                  </ActionButton>
                </div>
              </Grid>
            </Grid>
          </DialogActions>
          {endText !== "" && (
            <Box textAlign="center" pt="12px">
              {endText}
            </Box>
          )}
        </Box>
      )}
    </Dialog>
  );
};
