import {
    fetchLvConfigs,
    updateLvConfigData
  } from "../services/lvConfigService";
  
  type LvConfigs = {
    name: string;
    value: string;
    description: string;
  };
  
  export default class LvConfigManager {
    private getFormattedLvConfigs = (data: any[]) => {
      let result: LvConfigs[] = [];
      data.forEach((item: any) => {
        result.push({
          name: item?.name ?? "",
          value: item?.value ?? "",
          description: item?.description ?? "",
        });
      });
      return result;
    };

    async getLvConfigs(page: number) {
        try {
            let result = await fetchLvConfigs(page);
            let formattedData = this.getFormattedLvConfigs(result?.data?.results);
            return { formattedData, count: result?.data?.count };
        } catch (error) {
            throw error;
        }
    }

    async updateLvConfigs(data: any){
        try {
            let result = await updateLvConfigData(data);
            return result?.data;
        } catch (error) {
            throw error;
        }
    }
  }
  