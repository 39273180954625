import React, { CSSProperties } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  ListItemIcon,
  FormHelperText,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  styled,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Field } from "formik";
import { getColor } from "../Styles/Colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  smallRadioButton: {
    "& svg": {
      width: "16px",
      height: "16px",
    },
  },
  icon: {
    "& .MuiSvgIcon-root": { width: "14px" },
  },
});

export type RadioButtonSelectProps = {
  /**
   * by default row is true, you can set it to false then radioItems will be in column order
   */
  row?: boolean;

  /**
   *here you can set the position of label items with respect to radio icon
   */

  labelPosition?: any;
  /**
   * here options is the array of objects , here you can give icon to a particular field as well as you can an option disabled
   */

  options: {
    name: String;
    tooltipText?: String;
    icon?: any;
    value?: any;
    disabled?: boolean;
    width?: string;
    hide?: boolean;
  }[];

  /**
   * here you can pass form Label
   *
   */

  formLabel?: any;

  /**
   *onChange function
   *
   */

  onChange?: any;

  /**
   *value of RadioGroup
   *
   */

  value?: any;

  /**
   * additional css you can give  to the icon's div
   */
  iconStyles?: CSSProperties;
  /**unique identifier for input field */

  id?: string;

  /**unique name for input field */
  name?: string;

  /**implicit prop from formik. Dont have to pass as prop */
  form?: any;

  error?: boolean;

  index?: number;

  fieldArray?: boolean;

  formName?: string;

  fieldName?: string;
  required?: boolean;
};

export const RadioButton = ({
  options,
  row = true,
  labelPosition = "end",
  formLabel,
  onChange,
  value,
  name,
  id = "",
  form,
  iconStyles,
  error,
  fieldArray = false,
  fieldName = "",
  index = 0,
  formName = "",
  required = false,
}: RadioButtonSelectProps) => {
  const classes = useStyles();

  const labelPos = labelPosition;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (form) {
      if (fieldArray) {
        const newTouched = form.touched[formName] ?? [];
        newTouched[index] = { ...(newTouched[index] ?? {}), [fieldName]: true };
        form.setTouched({ ...form.touched, [formName]: newTouched });
      } else {
        form?.setTouched({ ...form.touched, [id]: true });
      }
      form?.setFieldValue(id, event.target.value);
    }
    onChange && onChange(event.target.value);
  };

  const getErrorMessage = () => {
    let errorMessage = "";
    if (form) {
      if (
        fieldArray &&
        error &&
        form?.touched?.[formName]?.[index]?.[fieldName]
      ) {
        errorMessage = form?.errors?.[formName]?.[index]?.[fieldName];
      } else if (error && form?.touched[id]) {
        errorMessage = form?.errors[id];
      }
    }
    return errorMessage;
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: getColor("shades", 0),
      "&:before": {
        border: `1px solid ${getColor("neutral", 200)}`,
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: getColor("shades", 0),
      border: `1px solid ${getColor("neutral", 200)}`,
      color: getColor("neutral", 900),
      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.08)",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "500",
      maxWidth: "277px",
    },
  }));

  return (
    <FormControl>
      <FormLabel required={required}>{formLabel ?? ""}</FormLabel>
      <RadioGroup
        row={row}
        onChange={handleChange}
        value={value}
        name={name}
        id={id}
      >
        {options &&
          options.map((option, index) => (
            <div
              key={index}
              style={{ display: option?.hide ? "none" : "inherit" }}
            >
              <FormControlLabel
                sx={{
                  color: getColor("border", 200),
                  fontWeight: 400,
                  fontFamily: "Work Sans",
                  paddingRight: "47px",
                  width: option?.width,
                  paddingLeft: "1px",
                }}
                labelPlacement={labelPos}
                disabled={option.disabled}
                value={option.value}
                className={classes.smallRadioButton}
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: getColor("primary", 700),
                      },
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      color: getColor("border", 200),
                    }}
                  >
                    {option.name}
                    {option.tooltipText ? (
                      <ListItemIcon
                        sx={{ minWidth: "auto", ...iconStyles }}
                        className={classes.icon}
                      >
                        <LightTooltip
                          title={option.tooltipText}
                          arrow
                          placement="top-start"
                        >
                          <InfoOutlinedIcon
                            data-testid="info-tooltip"
                            style={{ color: getColor("primary", 700) }}
                            fontSize="small"
                          />
                        </LightTooltip>
                      </ListItemIcon>
                    ) : option.icon ? (
                      option.icon
                    ) : (
                      <></>
                    )}
                  </span>
                }
              />
            </div>
          ))}
        <FormHelperText error={true}>{getErrorMessage()}</FormHelperText>
      </RadioGroup>
    </FormControl>
  );
};
export function RadioButtonSelect(props: any) {
  return <Field component={RadioButton} {...props} />;
}
