import {
  getInvestorInformation,
  getNomineeInformation,
  getDematInformation,
} from "../services/dashboardServices";
import { AuthContextProps, UserAuthInfo } from "../../../contexts/authContext";

export default class DashboardManager {
  private userData: UserAuthInfo;

  constructor(AuthContext: AuthContextProps) {
    this.userData = AuthContext.authState;
  }
  async getInvestorInformation(
    email: string,
    page: number,
    ca_doc_sent_status: string,
    profile_type: string
  ) {
    try {
      let result = await getInvestorInformation(
        email,
        page,
        ca_doc_sent_status,
        profile_type
      );
      result = result.data;
      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        // date: item.approval_date,
        // manager: item.relationship_manager,
        // phone_no: item.users_phone,
        location: item.city,
        onboarding_as: item.onboard,
        // type: item.investor_type,
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }

  async getNomineeInformation(email: string, page: number) {
    try {
      let result = await getNomineeInformation(email, page);
      result = result.data;
      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        // date: item.approval_date,
        // manager: item.relationship_manager,
        // phone_no: item.users_phone,
        location: item.city,
        onboarding_as: item.onboard,
        // type: item.investor_type,
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }

  async getDematInformation(email: string, page: number, tab: string) {
    try {
      let result = await getDematInformation(email, page, tab);
      result = result.data;
      let formattedData = result?.results?.map((item: any) => ({
        ...item,
        // date: item.approval_date,
        // manager: item.relationship_manager,
        // phone_no: item.users_phone,
        location: item.city,
        onboarding_as: item.onboard,
        // type: item.investor_type,
      }));
      return { formattedData, count: result?.count };
    } catch (error) {
      throw error;
    }
  }
}
