import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  overflow: hidden;
  scroll-behavior: disable;
  background: linear-gradient(white, white),
    radial-gradient(circle at top left, #ff9a9e, #fad0c4, #fad0c4),
    radial-gradient(circle at bottom right, #fad0c4, #ff9a9e, #fad0c4);
  background-blend-mode: screen;
`;

const Title = styled(Typography)`
  font-size: 2.5rem;
  color: #585858;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 500;
`;

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Title variant="h1">Welcome to Letsventure's Admin Portal</Title>
    </Container>
  );
};

export default Dashboard;
